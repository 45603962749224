import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AppConfigService } from '../service/appconfig.service';
import * as countries from '../../assets/countries/en.json';
import * as timeZones from '../../assets/timezones/en.json';
import { TranslateService } from '@ngx-translate/core';
import { ManageProfileService } from './manage-profile.service';
import * as languages from '../../assets/languages.json';
import { getErrorMessage } from '../shared/error-messages';
import { AlertBoxService } from '../alert-box/alert-box.service';
import { ActivatedRoute,NavigationExtras, Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { SearchTable } from '../model/searchTable.model';
import { SharedService } from '../shared/shared.service';
import { isEmail } from 'commons-validator-es';
import { containsURL } from 'src/app/shared/url-validator';
import { environment } from 'src/environments/environment';
import { AccountLinkingService } from '../account-linking/account-linking.service';


@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css'],
  // templateUrl: '../../'+environment.path+'manage-profile/manage-profile.component.html',
  // styleUrls: ['../../'+environment.path+'manage-profile/manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {
  envProperties: any;
  realmAuthDomain: any;
  public editPerson: FormGroup = new FormGroup({});
  public phone: FormArray = new FormArray([]);
  personDetails: any = {};
  public isShown = true;
  public isAddressShown = true;
  public isUpdatePasswordShown = true;
  public isResetPasswordShown = true;
  public isUpdateMfaShown = true;
  public isUpdatePhoneShown = true;
  public isUpdateQuestionsShown = true;
  public hideOrganisation = false;
  public isHelpDeskUser = false;
  public disableEditing = false;
  public hideOriginatedSystem = true;
  public hideRole = false;
  public fadeModal = false;
  securityQuestionsMap = new Map();
  public questionsArray: any[] = [];
  countries: any = (countries as any).default;
  timeZones: any = (timeZones as any).default;
  spin: boolean = true;
  submitSpinner: boolean = false;
  submitSpinnerReprocess: boolean = false;
  submitSpinnerPasswordLink: boolean = false;
  submitSpinnerSuspend: boolean = false;
  submitSpinnerUnsuspend: boolean = false;
  submitSpinnerDelete: boolean = false;
  submitSpinnerUnlock: boolean = false;
  submitSpinnerAnonymize: boolean = false;
  message: String = '';
  languages: any = (languages as any).default;
  public accountId: any;
  public syncUpdate: any;
  public role: any;
  public sourceSystem: any;
  public updateSourceSystem: any;
  public organisationName: any;
  public userName: any;
  public cuid: any;
  public userRoles: string[] | undefined;
  error!: String;
  public tableData!: SearchTable | null;
  public locationState!: any;
  public halfPassword!: string;
  @Input() userCuid?: string;
  @Output() backclicked: EventEmitter<string|null> = new EventEmitter<string|null>();

  showReprocess = false;
  showResendPassword = false;
  showResetPassword = false;
  showSuspend = false;
  showUnsuspend = false;
  showDelete = false;
  showUnlock = false;
  showAnonymize = false;
  isRealmAuthDomain = true;
  isEmployeeAuthDomain = false;
  showunlinkModal: boolean = false;
  showUnlinkButton: boolean = false;
  showUnlinkbtnOnStatus: boolean = false;
  selectedLanguage: any;
  tooltipTitle: any;
  
  IdpModel: any[] = [];
  selectedIdp: any;
  linkedPrimaryId: any;
  personStatus: any;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertBoxService,
    private route: ActivatedRoute,
    private location: LocationStrategy,
    private sharedService: SharedService,
    private httpClient: HttpClient,
    private manageProfileService: ManageProfileService,
    private language: TranslateService,
    private appConfigService: AppConfigService,
    private _location: Location,
    private router: Router,
    private accountLinkingService: AccountLinkingService,
  ) {
    this.editPerson = this.fb.group({
      id: [''],
      version: [''],
      creation: [''],
      realm: [''],
      status: [''],
      userType: [''],
      phoneVerifiedMode: [''],
      emailVerifiedMode: [''],
      preferredMode: [''],

      name: this.fb.group({
        prefix: ['', [Validators.maxLength(100)]],
        given: [
          '',
          [Validators.required, Validators.maxLength(150), this.checkForBlanks,containsURL],
        ],
        middle: ['', [Validators.maxLength(60),containsURL]],
        surname: [
          '',
          [Validators.required, Validators.maxLength(150), this.checkForBlanks,containsURL],
        ],
        suffix: ['', [Validators.maxLength(150),containsURL]],
      }),
      addresses: this.fb.array([
        this.fb.group({
          streets: this.fb.array([
            this.fb.control('', [Validators.maxLength(255),containsURL]),
            this.fb.control('', [Validators.maxLength(255),containsURL]),
          ]),
          city: ['', [Validators.maxLength(60),containsURL]],
          state: ['', [Validators.maxLength(60),containsURL]],
          postal: ['', [Validators.maxLength(10),containsURL]],
          country: ['', [Validators.required, Validators.maxLength(3)]],
        }),
      ]),
      phones: this.fb.array([
        this.fb.group({
          type: ['main'],
          number: ['',[containsURL]],
        }),
        this.fb.group({
          type: ['mobile'],
          number: ['',[containsURL]],
        }),
      ]),
      attributes: this.fb.array([
        this.fb.group({
          id: [''],
          name: [''],
          value: [''],
        }),
      ]),
      language: ['', [Validators.required]],
      timezone: ['', [Validators.required]],
      updateSourceSystem: ['OTIAM'],
      organization: this.fb.group({
        id: [''],
        type: [''],
        realm: [''],
      }),
      email: ['', [Validators.required, Validators.maxLength(1000), this.emailValidator,containsURL]],
      authDomain: [''],
      username: ['',[containsURL]],
    });
  }

  isPhoneNumberInvalid(index: number): boolean | null{
    const phoneControl = this.editPerson.get(`phones.${index}.number`);
    return phoneControl && phoneControl.invalid; 
  }

  isStreetInvalid(addressIndex: number, streetIndex: number): boolean | null{
    const streetControl = this.editPerson.get(`addresses.${addressIndex}.streets.${streetIndex}`);
    return streetControl && streetControl.invalid;
  }

  checkForBlanks(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).trim().length) {
      return null;
    }
    return { cannotBeBlank: true };
  }

  reset() {
    this.editPerson.updateValueAndValidity();
    this.loadReactiveForm(this.personDetails);
  }

  loadReactiveForm(personDetails: any) {
    this.editPerson.patchValue({
      id: personDetails.id,
      version: personDetails.version,
      creation: personDetails.creation,
      realm: personDetails.realm,
      status: personDetails.status,
      userType: personDetails.userType,
      phoneVerifiedMode: personDetails.phoneVerifiedMode,
      emailVerifiedMode: personDetails.emailVerifiedMode,
      preferredMode: personDetails.preferredMode,
      name: {
        prefix: personDetails.name.prefix,
        given: personDetails.name.given,
        middle: personDetails.name.middle,
        surname: personDetails.name.surname,
        suffix: personDetails.name.suffix,
      },
      addresses: this.setAddresses(personDetails.addresses),
      phones: this.setPhone(personDetails.phones),
      language: personDetails.language,
      timezone: personDetails.timezone,
      organization: {
        id: personDetails.organization.id,
        type: personDetails.organization.type,
        realm: personDetails.organization.realm,
      },
      email: personDetails.email,
      authDomain: personDetails.authDomain,
      username: personDetails.username,
    });
    if (personDetails.attributes) {
      this.editPerson.patchValue({
        attributes: this.setAttributes(personDetails.attributes),
      });
    }
    this.hideProperties();

    this.editPerson.markAllAsTouched();
  }
  loadTableData() {
    this.tableData = {
      cuid: this.cuid,
      status: this.personDetails.status,
      firstName: this.personDetails.name.given,
      email: this.personDetails.email,
      userId: this.personDetails.username,
      organisation: this.organisationName,
      syncUpdateStatus: this.syncUpdate || null,
      sourceSystem: this.sourceSystem,
      lastName: this.personDetails.name.surname,
    };
  }

  setAddresses(addresses: any[]): any[] {
    const formArry: any[] = [];
    const addressGroup = this.editPerson.controls.addresses as FormArray;
    const streets = addressGroup.controls[0] as FormGroup;
    streets.controls.streets.patchValue(['', '']);
    addresses?.forEach((addresses) => {
      formArry.push({
        streets: this.setStreets(addresses.streets),
        city: addresses.city,
        state: addresses.state,
        postal: addresses.postal,
        country: addresses.country,
      });
    });
    return formArry;
  }

  setStreets(Streets: any[]): any[] {
    const formArry: any[] = [];
    Streets?.forEach((Street) => formArry.push(Street));
    return formArry;
  }

  setAttributes(attributes: any[]): any[] {
    const formArry: any[] = [];
    attributes?.forEach((attribute) => {
      switch (attribute.name) {
        case 'Account ID':
          this.accountId = attribute.value;
          break;
        case 'sourceSystem':
          this.sourceSystem = attribute.value;
          break;
        case 'role':
          this.role = attribute.value;
          break;
        case 'updateSourceSystem':
          this.updateSourceSystem = attribute.value;
          break;
        case 'syncUpdate':
          this.syncUpdate = attribute.value;
          break;
        default:
      }
      formArry.push({
        id: attribute.id,
        name: attribute.name,
        value: attribute.value,
      });
    });
    return formArry;
  }

  setPhone(phones: any[]): any[] {
    const formArry: any[] = [];
    this.editPerson.controls.phones = new FormArray([
      this.fb.group({
        type: ['main'],
        number: ['',[containsURL]],
      }),
      this.fb.group({
        type: ['mobile'],
        number: ['',[containsURL]],
      }),
    ]);
    phones?.forEach((phone) => {
      if (phone.type == 'main') {
        const phoneGroup = this.phoneDetails.controls[0] as FormGroup;
        phoneGroup.patchValue({ number: phone.number });
      } else {
        const phoneGroup = this.phoneDetails.controls[1] as FormGroup;
        phoneGroup.patchValue({ number: phone.number });
      }
    });
    return formArry;
  }

  getPhoneNumber(phone: string) {
    var phoneArray: any[] = this.editPerson.value.phones;
    if (phoneArray) {
      const index = phoneArray.findIndex(
        (userPhone: any) => userPhone.type === phone
      );
      if (index > -1) {
        return phoneArray[index].number;
      }
    }
  }

  createItem(): FormGroup {
    return this.fb.group({
      type: [''],
      number: [''],
    });
  }

  reloadPage(){
    const navigationExtras: NavigationExtras = {
      queryParams: null,
    };
    this.router.navigate([], navigationExtras);
  }

  ngOnInit() {
    //this.reloadPage();
    this.envProperties = this.appConfigService.envProperties;
    this.realmAuthDomain = this.envProperties.defaultAuthDomain;
    this.locationState = this._location.getState();
    if (this.locationState.data) {
      this.tableData = this.locationState.data;
    }
    this.userRoles = this.appConfigService.appContext?.roles;
    this.selectedLanguage = this.appConfigService?.appContext?.user?.language;
    if (this.router.url.includes('search/manageAdminProfile')) {
      if (this.userCuid) {
        this.linkedPrimaryId = this.userCuid;
        this.cuid = this.userCuid;
      } 
      else {
        this.router.navigate(['/search']);
      }
    }
    this.personStatus = this.manageProfileService.retrievePersonRoles(this.cuid)
    console.log("this.personStatus",this.personStatus)
    
    /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
    /* if(this.cuid){
      this.translate.get('search-header-title').subscribe((text: string) => this.sharedService.setHeaderTitle(text));
      this.translate.get('search-header-subtitle').subscribe((text: string) => this.sharedService.setHeaderSubTitle(text));
    } else {
      this.translate.get('profile-header-title').subscribe((text: string) => this.sharedService.setHeaderTitle(text));
      this.translate.get('profile-header-subtitle').subscribe((text: string) => this.sharedService.setHeaderSubTitle(text));
    } */
    this.loadUser(this.cuid);
    this.loadCountriesAndTimezones();
    this.removeHeaderInformation();
    // setTimeout(()=>{                     
    //   this.removeHeaderInformation();
    // });
  }

  removeHeaderInformation(){
    const currentUrl = this._location.path();
    const urlContainsXsrf = currentUrl.includes('xsrfToken');
    if(urlContainsXsrf) {
      this.router.navigate([this.location.path().split('?')[0]], {
        queryParams: {
          'cuid': null,
          'xsrfToken': null,
        },
        queryParamsHandling: 'merge'
      })
    }
  }

  emailValidator(control: AbstractControl): ValidationErrors | null {
    if (
      isEmail(control.value) &&
      !control.value.toLowerCase().includes('@opentext.com')
    ) {
      return null;
    }
    if (control.value === '' && (control.touched || control.dirty)) {
      return { invalidFormat: true, message: 'empty' };
    }
    return { invalidFormat: true, message: 'format' };
  }

  loadUser(cuid: any) {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .fetchCurrentUser(cuid)
        .then((response: any) => {
          this.personDetails = response;
          this.getLinkiedAccountList(this.personDetails?.id);
          if (this.personDetails.status === 'unactivated')
            this.loadUnactivatedUsername();
          this.loadOrganization();
          this.loadReactiveForm(this.personDetails);
          resolve(response);
        })
        .fail(function (err: any) {
          reject(err);
        });
    });
    return promise;
    /*     this.httpClient.get("assets/mock-json/personDetails.json").subscribe(data => {
      this.spin = false;
      this.personDetails = data;
      if (this.personDetails.status === 'unactivated')
        this.loadUnactivatedUsername()
      // this.loadOrganization();
      this.loadReactiveForm(this.personDetails);
      this.hideProperties();
    })
    return new Promise((res,err) => {}); */
  }

  enableUnlinkModal() {
    this.showunlinkModal = true;
  }

  getLinkiedAccountList(cuid: any) {
    return new Promise((resolve, reject) => {
        this.accountLinkingService.getLinkedAccountsListForSystemAdmin(cuid)
          .then((response: any) => {
            this.IdpModel = response;
            this.tooltipTitle = this.IdpModel[0]?.primaryUserName;
            if(this.IdpModel.length > 0) {
              this.showUnlinkButton = true;
            } else {
              this.showUnlinkButton = false;
            }
            resolve(response);
          })
          .fail((err: any) => {
            // this.error = getErrorMessage(err?.responseJSON, 'search-error');
          });

    });
  }

  confirmUnlinkIdp(idp: any) {
    this.showunlinkModal = false;
    this.selectedIdp = idp;
  }

  unlinkIdp(id: any, linkedUserName: any) {  
    const promise = new Promise((resolve, reject) => {
      this.accountLinkingService
        .unlinkIdpLinkedSearchPage(id)
        .then((response: any) => {
          if(response.statusCode == 200) {
            this.alertService.show("Your account has been unlinked. You  can re-link  accounts  anytime  in the ‘Linked accounts’ tab.", {
              classname: 'alert-success',
            });
            resolve(response);
          }
          this.getLinkiedAccountList(this.personDetails?.id);
        })
        .fail((err: any) => {
          this.alertService.show('Account unlink failed. Please try again.', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  loadUnactivatedUsername() {
    this.manageProfileService
      .getUnactivatedUsername(this.cuid)
      .then((response: any) => {
        this.userName = response[0].userId;
        this.personDetails.username = response[0].userId;
        this.editPerson.controls.username.patchValue(
          this.personDetails.username
        );
      })
      .fail((err: any) => {
        this.error = getErrorMessage(err?.responseJSON, 'load-username-error');
        this.alertService.show(String(this.error), {
          classname: 'alert-danger',
        });
      });
  }

  loadOrganization() {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .getOrganization(this.personDetails.organization.id)
        .then((response: any) => {
          this.organisationName = response.name;
          this.personDetails.orgName = response.name;
          this.spin = false;
          resolve(response);
        })
        .fail((err: any) => {
          this.spin = false;
          this.error = getErrorMessage(
            err?.responseJSON,
            'load-organisation-error'
          );
          this.alertService.show(String(this.error), {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  hideProperties() {
    this.showReprocess = false;
    this.showResendPassword = false;
    this.showResetPassword = false;
    this.showSuspend = false;
    this.showUnsuspend = false;
    this.showDelete = false;
    this.showUnlock = false;
    this.showAnonymize = false;

    let userAuthDomain = this.personDetails.authDomain;
    let userStatus = this.personDetails.status;

    if (this.personDetails.userType.toUpperCase() === 'VISITOR') {
      this.hideOrganisation = true;
      this.hideRole = true;
    }

    if (userAuthDomain?.toUpperCase() !== this.realmAuthDomain?.toUpperCase()) {
      this.hideOriginatedSystem = false;
      this.disableEditing = true;
      this.isRealmAuthDomain = false;
    }

    if (userAuthDomain?.toUpperCase() === 'EMPLOYEE') {
      this.isEmployeeAuthDomain = true;
      this.disableEditing = true;
    }
    
        if (
      this.userRoles?.indexOf('Help Desk Plus') !== -1 ||
      this.userRoles?.indexOf('Security Administrator') !== -1
    ) {
      this.isHelpDeskUser = true;
      this.hideOriginatedSystem = false;
      this.hideOrganisation = false;
      this.hideRole = false;
      // Show Action for default Auth domain based on Status
      if (
        userAuthDomain?.toUpperCase() === this.realmAuthDomain?.toUpperCase()
      ) {
        if (
          this.userRoles?.indexOf('Help Desk Plus') !== -1 &&
          this.userRoles?.indexOf('Security Administrator') !== -1
        ) {
          this.showAnonymize = true;
        }

        if (userStatus.toUpperCase() === 'ACTIVE') {
          this.showUnlinkbtnOnStatus = true;
          this.showResetPassword = true;
          if (this.userRoles?.indexOf('Security Administrator') !== -1) {
            this.showSuspend = true;
          }
          this.showReprocess = true;
        }
        if (userStatus.toUpperCase() === 'LOCKED') {
          this.showResetPassword = true;
          this.showUnlinkbtnOnStatus = true;
          if (this.userRoles?.indexOf('Security Administrator') !== -1) {
            this.showSuspend = true;
          }
          this.showReprocess = true;
          this.showUnlock = true;
        }
        if (userStatus.toUpperCase() === 'SUSPENDED') {
          this.showUnlinkbtnOnStatus = false;
          if (this.userRoles?.indexOf('Security Administrator') !== -1) {
            this.showUnsuspend = true;
            this.showDelete = true;
          }
          this.showReprocess = true;
        }
        if (userStatus.toUpperCase() === 'UNACTIVATED') {
          this.showResendPassword = true;
          this.showUnlinkbtnOnStatus = false;
          if (this.userRoles?.indexOf('Security Administrator') !== -1) {
            this.showDelete = true;
          }
          this.showReprocess = true;
        }
        if (userStatus.toUpperCase() === 'PENDING') {
          this.showResendPassword = true;
          this.showUnlinkbtnOnStatus = false;
          if (this.userRoles?.indexOf('Security Administrator') !== -1) {
            this.showDelete = true;
          }
          this.showReprocess = true;
        }
        if (userStatus.toUpperCase() === 'INACTIVE') {
          this.disableEditing = true;
          this.showUnlinkbtnOnStatus = false;
        }
      }
      // Show Anonymize button for Other Auth domains except EMPLOYEE
      else if (userAuthDomain?.toUpperCase() !== 'EMPLOYEE') {
        if (
          this.userRoles?.indexOf('Help Desk Plus') !== -1 &&
          this.userRoles?.indexOf('Security Administrator') !== -1
        ) {
          this.showAnonymize = true;
        }

        if (userStatus.toUpperCase() !== 'INACTIVE') {
          this.showReprocess = true;
        }
      }

      //Hide Anonymize button for Already Anonymized Users
      if (this.personDetails.email === 'NA') {
        this.showAnonymize = false;
      }
    }
  }

  resendPassword() {
    this.submitSpinnerPasswordLink = true;
    if (this.personDetails.status == 'pending') {
      const promise = new Promise((resolve, reject) => {
        this.manageProfileService
          .resendActivationLink(this.personDetails.id)
          .then((response: any) => {
            this.submitSpinnerPasswordLink = false;
            this.alertService.show('resend-link-success', {
              classname: 'alert-success',
            });
            resolve(response);
          })
          .fail((err: any) => {
            this.submitSpinnerPasswordLink = false;
            this.error = getErrorMessage(
              err?.responseJSON,
              'resend-link-error'
            );
            this.alertService.show(String(this.error), {
              classname: 'alert-danger',
            });
          });
      });
      return promise;
    } else {
      const promise = new Promise((resolve, reject) => {
        this.manageProfileService
          .resendActivationLinkCSR(this.userName, this.personDetails.email)
          .then((response: any) => {
            this.submitSpinnerPasswordLink = false;
            this.alertService.show('resend-link-success', {
              classname: 'alert-success',
            });
            resolve(response);
          })
          .fail((err: any) => {
            this.submitSpinnerPasswordLink = false;
            this.error = getErrorMessage(
              err?.responseJSON,
              'resend-link-error'
            );
            this.alertService.show(String(this.error), {
              classname: 'alert-danger',
            });
          });
      });
      return promise;
    }
  }

  submitReprocess() {
    this.submitSpinnerReprocess = true;
    if (!this.tableData) {
      this.loadTableData();
    }
    return new Promise((resolve, reject) => {
      this.manageProfileService
        .submitPersonsReprocess(this.tableData)
        .then((response: SearchTable) => {
          this.alertService.show('Sucessfully submited to ServiceNow', {
            classname: 'alert-success',
          });
          this.error = '';
          this.submitSpinnerReprocess = false;
          this.sharedService.updateSyncStatus(response);
          this.tableData = null;
          resolve(response);
        })
        .fail((err: any) => {
          this.submitSpinnerReprocess = false;
          this.alertService.show('Error While Invoking ServiceNow API', {
            classname: 'alert-danger',
          });
          this.tableData = null;
        });
    });
  }

  suspendPerson() {
    this.submitSpinnerSuspend = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .suspendPerson(this.personDetails.id)
        .then((response: any) => {
          this.loadUser(this.cuid).then((userRes: any) => {
            this.submitSpinnerSuspend = false;
            this.showSuspend = false;
            this.alertService.show('Sucessfully suspended the user', {
              classname: 'alert-success',
            });
            let data = {
              cuid: this.cuid,
              status: userRes.status,
              firstName: '',
              lastName: '',
              email: '',
              userId: '',
              organisation: '',
              syncUpdateStatus: response.syncStatus,
              sourceSystem: '',
            };
            this.sharedService.updateSyncStatus(data);
            resolve(response);
          });
        })
        .fail((err: any) => {
          this.submitSpinnerSuspend = false;
          this.alertService.show('Error while suspending the user', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  unsuspendPerson() {
    this.submitSpinnerUnsuspend = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .unsuspendPerson(this.personDetails.id)
        .then((response: any) => {
          this.loadUser(this.cuid).then((userRes: any) => {
            this.submitSpinnerUnsuspend = false;
            this.alertService.show('Sucessfully unsuspended the user', {
              classname: 'alert-success',
            });
            let data = {
              cuid: this.cuid,
              status: userRes.status,
              firstName: '',
              lastName: '',
              email: '',
              userId: '',
              organisation: '',
              syncUpdateStatus: response.syncStatus,
              sourceSystem: '',
            };
            this.sharedService.updateSyncStatus(data);
            resolve(response);
          });
        })
        .fail((err: any) => {
          this.submitSpinnerUnsuspend = false;
          this.alertService.show('Error while unsuspending the user', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  deletePerson() {
    this.submitSpinnerDelete = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .deletePerson(this.personDetails.id)
        .then((response: any) => {
          this.loadUser(this.cuid).then((userRes: any) => {
            this.submitSpinnerDelete = false;
            this.alertService.show('Sucessfully deleted the user', {
              classname: 'alert-success',
            });
            let data = {
              cuid: this.cuid,
              status: userRes.status,
              firstName: '',
              lastName: '',
              email: '',
              userId: '',
              organisation: '',
              syncUpdateStatus: response.syncStatus,
              sourceSystem: '',
            };
            this.sharedService.updateSyncStatus(data);
            resolve(response);
          });
        })
        .fail((err: any) => {
          this.submitSpinnerDelete = false;
          this.alertService.show('Error while deleting the user', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  unlockPerson() {
    this.submitSpinnerUnlock = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .unlockPerson(this.personDetails.id)
        .then((response: any) => {
          this.loadUser(this.cuid).then((userRes: any) => {
            this.submitSpinnerUnlock = false;
            this.alertService.show('Sucessfully unlocked the user', {
              classname: 'alert-success',
            });
            let data = {
              cuid: this.cuid,
              status: userRes.status,
              firstName: '',
              lastName: '',
              email: '',
              userId: '',
              organisation: '',
              syncUpdateStatus: '',
              sourceSystem: '',
            };
            this.sharedService.updateSyncStatus(data);
            resolve(response);
          });
        })
        .fail((err: any) => {
          this.submitSpinnerUnlock = false;
          this.alertService.show('Error while unlocking the user', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  openAnonymizePopup() {
    if(this.personDetails.status == 'inactive') {
      this.anonymizePerson();
    } else {
      document.getElementById("launch-modal")?.click();
    }
    // this.anonymizePerson();
  }

  anonymizePerson() {
    this.submitSpinnerAnonymize = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .anonymizePerson(this.personDetails.id)
        .then((response: any) => {
          this.loadUser(this.cuid).then((userRes: any) => {
            this.submitSpinnerAnonymize = false;
            this.showUnlinkButton = false;
            this.alertService.show('Sucessfully anonymized the user', {
              classname: 'alert-success',
            });
            let data = {
              cuid: this.cuid,
              status: userRes.status,
              firstName: '',
              lastName: '',
              email: '',
              userId: '',
              organisation: '',
              syncUpdateStatus: '',
              sourceSystem: '',
            };
            this.sharedService.updateSyncStatus(data);
            resolve(response);
          });
        })
        .fail((err: any) => {
          this.submitSpinnerAnonymize = false;
          this.alertService.show(
            'PII records clean up for user ' + this.cuid + ' is scheduled',
            {
              classname: 'alert-danger',
            }
          );
        });
    });
    return promise;
  }

  goBack() {
    this.backclicked.emit(null);
    this.router.navigate(['/search']);
  }

  onSubmit(id: any, formValid: any) {
    if (formValid) {
      this.submitSpinner = true;

      const promise = new Promise((resolve, reject) => {
        this.manageProfileService
          .updateCurrentPerson(this.editPerson.getRawValue(), this.cuid)
          .then((response: any) => {
            this.personDetails = response;
            if (this.cuid) {
              let data = {
                cuid: this.cuid,
                status: this.personDetails.status,
                firstName: this.personDetails.name.given,
                lastName: this.personDetails.name.surname,
                email: '',
                userId: '',
                organisation: '',
                syncUpdateStatus: '',
                sourceSystem: '',
              };
              this.sharedService.updateSyncStatus(data);
            }
            if (!this.cuid) {
              this.loadLanguage();
            }
            if (!this.cuid && this.editPerson.get(['name'])?.valueChanges)
              this.sharedService.updateUsername(
                this.editPerson.get(['name', 'given'])?.value +
                  ' ' +
                  this.editPerson.get(['name', 'surname'])?.value
              );
            this.processId(response, id);
          })
          .fail((err: any) => {
            this.submitSpinner = false;
            document.getElementById(id)?.click();
            this.message = getErrorMessage(
              err.responseJSON,
              'update-person-error'
            );
            this.alertService.show(String(this.message), {
              classname: 'alert-danger',
            });
            reject(err);
          });
      });
    } else {
      this.editPerson.markAllAsTouched();
    }
  }

  processId(response: any, id: any) {
    var updateMFAModeValue: boolean = false;
    var updateEmail: boolean = false;
    if (this.editPerson.get('email')?.dirty) {
      this.editPerson.get('email')?.markAsPristine();
      updateEmail = true;
    }

    if (
      this.phoneDetails.controls[0].dirty &&
      (this.editPerson.controls.preferredMode.value === 'PHONE' ||
        this.editPerson.controls.preferredMode.value === 'SMS') &&
      this.editPerson.controls.phoneVerifiedMode.value === 'MAIN'
    ) {
      this.phoneDetails.controls[0]?.markAsPristine();
      updateMFAModeValue = true;
    }

    if (
      this.phoneDetails.controls[1].dirty &&
      (this.editPerson.controls.preferredMode.value === 'PHONE' ||
        this.editPerson.controls.preferredMode.value === 'SMS') &&
      this.editPerson.controls.phoneVerifiedMode.value === 'MOBILE'
    ) {
      this.phoneDetails.controls[1]?.markAsPristine();
      updateMFAModeValue = true;
    }

    this.submitSpinner = false;
    document.getElementById(id)?.click();
    this.editPerson.updateValueAndValidity();

    if (updateEmail && !this.cuid) {
      this.alertService.show('verify-updated-email', {
        classname: 'alert-info',
      });
    } else if (updateEmail && this.cuid) {
      if(response.status!='unactivated'){
      this.alertService.show('verify-updated-email-hd', {
        classname: 'alert-info',
      });}
      else{
        this.alertService.show('update-person-success', {
          classname: 'alert-success',
        });}
    } else if (updateMFAModeValue) {
      this.alertService.show('update-person-success-validate-mfa', {
        classname: 'alert-success',
      });
    } else {
      this.alertService.show('update-person-success', {
        classname: 'alert-success',
      });
    }
  }

  get address(): FormArray {
    return this.editPerson.get('addresses') as FormArray;
  }

  get phoneDetails(): FormArray {
    return this.editPerson.get('phones') as FormArray;
  }

  get attributes(): FormArray {
    return this.editPerson.get('attributes') as FormArray;
  }

  Streets(Index: number): FormArray {
    return this.address.at(Index).get('streets') as FormArray;
  }

  newPhone(): FormGroup {
    return this.fb.group({
      type: [''],
      number: [''],
    });
  }

  addStreets(empIndex: number) {
    this.Streets(empIndex).push(new FormControl(''));
  }

  addPhone(): void {
    this.phone = this.editPerson.get('phones') as FormArray;
    this.phone.push(this.createItem());
  }

  addAddress() {
    this.address.push(
      this.fb.group({
        streets: this.fb.array([this.fb.control('')]),
        city: [''],
        state: [''],
        postal: [''],
        country: [''],
      })
    );
  }

  getName() {
    return this.editPerson.get(['name', 'given'])?.value;
  }

  getFullName() {
    return (
      this.editPerson.get(['name', 'given'])?.value +
      ' ' +
      (this.editPerson.get(['name', 'middle'])?.value || '') +
      ' ' +
      this.editPerson.get(['name', 'surname'])?.value
    );
  }

  getFullNameWithPrefixSuffix() {
    return (
      (this.editPerson.get(['name', 'prefix'])?.value || '') +
      ' ' +
      this.editPerson.get(['name', 'given'])?.value +
      ' ' +
      (this.editPerson.get(['name', 'middle'])?.value || '') +
      ' ' +
      this.editPerson.get(['name', 'surname'])?.value +
      ' ' +
      (this.editPerson.get(['name', 'suffix'])?.value || '')
    );
  }

  getUserAddress(test: any) {
    const addrs = this.address.controls[0] as FormGroup;
    return addrs.controls[test].value;
  }

  getNotificationFromPassword(evt: any) {
    this.isUpdatePasswordShown = true;
  }

  getNotificationFromResetPassword(evt: any) {
    this.isResetPasswordShown = true;
  }

  getNotificationFromMfa(evt: any) {
    this.isUpdateMfaShown = true;
    this.loadUser(this.cuid);
  }

  getNotificationFromQuestions(evt: any) {
    this.isUpdateQuestionsShown = evt;
  }

  loadLanguage() {
    var currentLanguage = this.editPerson.get('language')?.value;
    this.language.setDefaultLang(currentLanguage);
    this.httpClient
      .get('../../../assets/countries/' + currentLanguage + '.json')
      .subscribe((data: any) => {
        this.countries = data;
      });
    this.httpClient
      .get('../../../assets/timezones/' + currentLanguage + '.json')
      .subscribe((data: any) => {
        this.timeZones = data;
      });
  }

  loadCountriesAndTimezones() {
    if (this.language.defaultLang) {
      this.httpClient
        .get('../../../assets/countries/' + this.language.defaultLang + '.json')
        .subscribe((data: any) => {
          this.countries = data;
        });
      this.httpClient
        .get('../../../assets/timezones/' + this.language.defaultLang + '.json')
        .subscribe((data: any) => {
          this.timeZones = data;
        });
    }
  }

  TimeZones() {
    if (!this.editPerson.get('timezone')) {
      let timezone;
      let flag = 0;
      this.timeZones.forEach((element: { id: any; label: String }) => {
        if (element.id == Intl.DateTimeFormat().resolvedOptions().timeZone)
          flag = 1;
      });
      if (flag == 1)
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      else timezone = 'EST5EDT';
      this.editPerson.get('timezone')?.patchValue(timezone);
    }
    return this.timeZones;
  }

  setQuestion(questions: any[]) {
    questions.forEach((questionObj) => {
      this.questionsArray.push(
        this.securityQuestionsMap.get(questionObj.question.id)
      );
    });
  }

  filterDigitsOnlyforMobile(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    this.editPerson.get('number')?.setValue(input.value, { emitEvent: false });
  }
}
