<app-header></app-header>
<app-alert-box class="col-lg-5 col-md-12 col-xs-12" aria-live="polite" aria-atomic="true"></app-alert-box>
<main class="main" id="main">
    <section class="section">
        <div class="container">
            <header class="section-header section-header-sub" [ngClass]="isLoggedIn ? 'section-top-custom-auth':'section-top-custom-unauth'">
              <!-- Commenting the Title and Sub Title as it is decided to remove the sub title for this release -->
              <!-- <div class="section-header-body">
                    <h1 class="section-header-title" *ngIf="!isLoggedIn && headerTitle && headerTitle !== ''">{{headerTitle}}</h1>
                    <h2 class="section-header-title" *ngIf="isLoggedIn && headerTitle && headerTitle !== ''">{{headerTitle}}</h2>
                    <div class="content-box section-header-subtitle" *ngIf="headerSubtitle && headerSubtitle !== ''">
                        <p>{{headerSubtitle}}</p>
                    </div>
                </div> -->
            </header>
            <router-outlet></router-outlet>
        </div>
    </section>
</main>
<app-footer></app-footer>
