export class CustomEnvAPI {
 
static defs = [
    
    {
        "cmd": "resetGenerateLink",
        "cmdType": "secured",
        "call": "/authn/v4/password/tasks/reset/generatelink",
        "type": "POST",
        "accepts": "application/vnd.com.covisint.platform.password.reset.generatelinkrequest.v1+json",
        "contentType": "application/vnd.com.covisint.platform.password.reset.generatelinkrequest.v1+json",
        "headers":{}
     }
    
    
]  
}