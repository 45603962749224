import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigModule } from './appconfig.module';
import { AppConfigService } from './service/appconfig.service';
import { EnvironmentConfigService } from './service/EnvironmentConfig.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { SpinnersAngularModule } from 'spinners-angular';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePasswordComponent } from './manage-profile/update-password/update-password.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from './shared/shared.module';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { MyApplicationComponent } from './my-application/my-application.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IsEllipsisActiveDirective } from './custom-directives/is-ellipsis-active.directive';
import { UpdateMfaComponent } from './manage-profile/update-mfa/update-mfa.component';
import { ResetPasswordComponent } from './manage-profile/reset-password/reset-password.component';
import { AccountLinkingComponent } from './account-linking/account-linking.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ManageProfileComponent,
    EmailConfirmationComponent,
    UpdatePasswordComponent,
    SearchUserComponent,
    IsEllipsisActiveDirective,
    AlertBoxComponent,
    MyApplicationComponent,
    LandingPageComponent,
    UpdateMfaComponent,
    ResetPasswordComponent,
    AccountLinkingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AppConfigModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NoopAnimationsModule,
    SharedModule,
    SpinnersAngularModule,
    NgbModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
    
  ],
  exports: [IsEllipsisActiveDirective],
  providers: [AppConfigService,EnvironmentConfigService],
  bootstrap: [AppComponent]
})
export class AppModule { }
