export class CustomAPI {
  static defs = [
      {
        "cmd": "register",
        "cmdType": "unsecured",
        "type": "POST",
        "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
        "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json",
        "call": "/person/v3/persons/registration"
      },
      {
        "cmd": "postPersonRequestNonce",
        "cmdType": "unsecured",
        "type": "POST",
        "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
        "contentType": "application/vnd.com.covisint.platform.person.request.v1+json",
        "call": "/registration/v1/registrations/persons/requests"
      },
      { "cmd": "getTokens",
        "call": "oauth/v3/token",
        "type": "POST",
        "accepts": "application/json"
      },
      {
        "cmd": "upassword",
        "cmdType": "unsecured",
        "type": "PUT",
        "accepts": "application/vnd.com.covisint.platform.person.account.password.v1+json",
        "contentType": "application/vnd.com.covisint.platform.person.account.password.v1+json",
        "call": "person/v3/persons/82D6FEB07DFB4E73AA06CCDA4441D7BA/accounts/password"
      },
      {
        "cmd": "getPerson",
        "cmdType": "secured",
        "call": "/person/v3/persons/{personId}",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.person.v1+json;lastlogindate=true"
      },
      {
        "cmd": "getOrganizations",
        "cmdType": "secured",
        "call": "/organization/v3/organizations",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.organization.v1+json"
      },
      {
        "cmd": "getSecurityq",
        "cmdType": "secured",
        "call": "/organization/v1/securityQuestions",
        "type": "GET",
        "accepts": "application/json"
      },
      {
        "cmd": "walkupRegistration",
        "cmdType": "nonce",
        "call": "/user/v1/registration",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json",
      }, 
      {
        "cmd": "passwordPolicy",
        "cmdType": "nonce",
        "call": "/organization/v1/policy?cuid={cuid}",
        "type": "GET",
        "accepts": "application/json",
        "contentType": "application/json",
      },
      {
        "cmd": "getSiteKey",
        "cmdType": "nonce",
        "call": "/application/v1/googleReCaptcha/siteKey",
        "type": "GET",
        "accepts": "*/*",
      },
      {
        "cmd": "emailValidation",
        "cmdType": "nonce",
        "call": "/user/v1/activate/validateLink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json",
      },
      {
        "cmd": "emailUpdateValidation",
        "cmdType": "nonce",
        "call": "/user/v1/emailUpdate/validateLink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json",
      },
      {
        "cmd": "checkUser",
        "cmdType": "secured",
        "call": "/person/v3/persons?username={username}",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.person.v1+json",
        "contentType": "application/vnd.com.covisint.platform.person.v1+json",
      },{
        "cmd": "CsrResister",
        "cmdType": "nonce",
        "call": "/user/v1/updatePassword",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json",
      },{
        "cmd": "validateLink",
        "cmdType": "secured",
        "call": "/authn/v4/password/tasks/reset/validatelink",
        "type": "POST",
        "accepts": "application/vnd.com.covisint.platform.password.reset.validatelinkresponse.v1+json",
        "contentType": "application/vnd.com.covisint.platform.password.reset.validatelinkresponse.v1+json",
      },
      // {
      //   "cmd": "searchUsers",
      //   "call": "/user/v1/search",
      //   "type": "POST",
      //   "accepts": "application/json",
      //   "contentType": "application/json"
      // },
      {
        "cmd": "getPersonByCuid",
        "cmdType": "secured",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
        "contentType": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
        "call": "/person/v3/persons"
      },
      {
        "cmd": "anonymization",
        "cmdType": "secured",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
        "contentType": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
        "call": "/user/v1/anonymization/{{cuid}}"
      },
      {
        "cmd": "personUpdate",
        "cmdType": "secured",
        "call": "/user/v1/updatePerson",
        "type": "PUT",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "resendActivationLink",
        "cmdType": "secured",
        "call": "/user/v1/activate/generateLink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "resendActivationLinkForCSR",
        "cmdType": "secured",
        "call": "/user/v1/reset/generateLink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "activateGenerateLink",
        "cmdType": "secured",
        "call": "/person/v3/persons/tasks/activate/generatelink",
        "type": "POST",
        "accepts": "application/vnd.com.covisint.platform.person.activate.generatelinkrequest.v1+json",
        "contentType": "application/vnd.com.covisint.platform.person.activate.generatelinkrequest.v1+json"
      },
      // {
      //   "cmd": "resetGenerateLink",
      //   "cmdType": "secured",
      //   "call": "/authn/v4/password/tasks/reset/generatelink",
      //   "type": "POST",
      //   "accepts": "application/vnd.com.covisint.platform.password.reset.generatelinkrequest.v1+json",
      //   "contentType": "application/vnd.com.covisint.platform.password.reset.generatelinkrequest.v1+json"
      // },
      {
        "cmd": "getUsernameForCSR",
        "cmdType": "secured",
        "call": "/passwordMigration/v1/password/migration",
        "type": "GET",
        "accepts": "application/vnd.com.covisint.platform.migrate.password.data.v1+json",
        "contentType": "application/vnd.com.covisint.platform.migrate.password.data.v1+json"
      },
      {
        "cmd": "invalidateSecretLink",
        "cmdType": "secured",
        "call": "/authn/v4/password/tasks/reset/deletelink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "forgotPassword",
        "cmdType": "secured",
        "call": "/user/v1/forgotPassword",
        "type": "GET",
        "accepts": "text/plain",
        "contentType": "application/json"
      },
      {
        "cmd": "personsCount",
        "cmdType": "secured",
        "call": "/user/v1/count",
        "type": "GET",
        "accepts": "text/plain",
        "contentType": "application/json"
      },
      {
        "cmd": "linkedAccountList",
        "cmdType": "secured",
        "call": "/user/accountlink/v1/linkedaccounts",
        "type": "GET",
        "accepts": "text/plain",
        "contentType": "application/json"
      },
      {
        "cmd": "unlinkIdpSearch",
        "cmdType": "secured",
        "call": "/user/accountlink/v1/unlinkaccount/search",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "unlinkIdpLinkedAccounts",
        "cmdType": "secured",
        "call": "/user/accountlink/v1/unlinkaccount/linkedaccounts",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "usersSearch",
        "cmdType": "secured",
        "call": "/user/v1/search",
        "type": "GET",
        "accepts": "text/plain",
        "contentType": "application/json"
      },{
        "cmd": "usersSearchReprocess",
        "cmdType": "secured",
        "call": "/user/v1/search/reprocessUsers",
        "type": "GET",
        "accepts": "text/plain",
        "contentType": "application/json"
      },{
        "cmd": "validateCSRLink",
        "cmdType": "nonce",
        "call": "/user/v1/reset/validateLink",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "submitPersonReprocess",
        "cmdType": "secured",
        "call": "/user/v1/serviceNow/submit/reprocess",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "productLine",
        "cmdType": "nonce",
        "call": "/organization/v1/productLine",
        "type": "GET",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "unlockPerson",
        "cmdType": "secured",
        "call": "/user/v1/unlock",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "suspendPerson",
        "cmdType": "secured",
        "call": "/user/v1/suspend",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "unsuspendPerson",
        "cmdType": "secured",
        "call": "/user/v1/unsuspend",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        "cmd": "deletePerson",
        "cmdType": "secured",
        "call": "/user/v1/delete",
        "type": "POST",
        "accepts": "application/json",
        "contentType": "application/json"
      },
      {
        cmd: 'anonymizePerson',
        cmdType: 'secured',
        call: '/user/v1/anonymize',
        type: 'POST',
        accepts: 'application/json',
        contentType: 'application/json',
      }   
  ]  
  
  // static personDetails = 
  // {
  //     "id": "6764BA5A511D4DED90864DEBC352F76A",
  //     "version": "1629891337000",
  //     "creation": 1629891337000,
  //     "realm": "PP-IAM-DEV",
  //     "status": "active",
  //     "userType": "au",
  //     "phoneVerifiedMode": "",
  //     "emailVerifiedMode": "SECRET",
  //     "preferredMode": "EMAIL",
  //     "name": {
  //         "prefix": "Mr.",
  //         "given": "Pegasus",
  //         "middle": "IAM",
  //         "surname": "devenv"
  //     },
  //     "addresses": [
  //         {
  //             "streets": [
  //                 "15 W Hubbard"
  //             ],
  //             "city": "Chicago",
  //             "state": "IL",
  //             "postal": "60654",
  //             "country": "US"
  //         }
  //     ],
  //     "language": "en",
  //     "timezone": "CST6CDT",
  //     "phones": [
  //         {
  //             "type": "main",
  //             "number": "312-933-3433"
  //         },
  //         {
  //             "type": "mobile",
  //             "number": "312-933-3433"
  //         }
  //     ],
  //     "email": "akshatj@opentext.com",
  //     "organization": {
  //         "id": "OPP-IAM-DEV85002",
  //         "type": "organization",
  //         "realm": "PP-IAM-DEV"
  //     },
  //     "username": "JAVATEST-31",
  //     "authDomain": "PP-IAM-DEV"
  // }
  
  }