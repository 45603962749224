import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './service/appconfig.service';
import { SharedService } from './shared/shared.service';
import { LocationStrategy } from '@angular/common';
import { AlertBoxService } from './alert-box/alert-box.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var cui: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // templateUrl: '../' + environment.path + 'app.component.html',
  // styleUrls: ['../' + environment.path + 'app.component.css']
})
export class AppComponent implements OnInit {
  title = 'pegasus-ui';
  headerTitle = '';
  headerSubtitle = '';
  navbarElement: any;
  isLoggedIn = false;

  constructor(
    private translate: TranslateService,
    private appConfigService: AppConfigService,
    private sharedService: SharedService,
    private alertService: AlertBoxService,
    private location: LocationStrategy,
    private router: Router) {

    history.pushState(null, '', window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      this.router.navigateByUrl(this.router.url);
      this.alertService.show("back-button-disabled", { classname: 'alert-danger' });
    });
  }


  @HostListener('window:scroll') onScroll(e: Event): void {
    if (window.scrollY !== 0) {
      this.sharedService.scrolled = true;
    } else {
      this.sharedService.scrolled = false;
    }
  }
  ngOnInit() {

    this.setLanguage();
    if (this.appConfigService.appContext?.user.name.given)
      this.isLoggedIn = true;
  }


  /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
  /* ngAfterViewChecked() {
    if (this.headerTitle !== this.sharedService.getHeaderTitle() || this.headerSubtitle !== this.sharedService.getHeaderSubTitle()) {
      this.headerTitle = this.sharedService.getHeaderTitle();
      this.headerSubtitle = this.sharedService.getHeaderSubTitle();
      this.ref.detectChanges();
    }
  } */

  setLanguage() {
    var language;
    if (this.appConfigService.userContext?.user?.language) {
      if (this.appConfigService.userContext.user.language == 'es' ||
        this.appConfigService.userContext.user.language == 'pt' ||
        this.appConfigService.userContext.user.language == 'fr' ||
        this.appConfigService.userContext.user.language == 'ja' ||
        this.appConfigService.userContext.user.language == 'de') {
        language = this.appConfigService.userContext.user.language;
      }
      else {
        language = 'en'
      }


    }
    else {
      var browserLanguage = navigator.language.slice(0, 2);
      if (browserLanguage == 'es' || browserLanguage == 'pt' || browserLanguage == 'fr' || browserLanguage == 'ja' || browserLanguage == 'de') {
        language = browserLanguage
      }
      else {
        language = 'en'
      }


    }

    this.translate.setDefaultLang(language);
  }

  addStyles() {
    console.log("called");
    if (window.scrollY != 0) {
      this.navbarElement = document.getElementById("navbar");
      this.navbarElement.classList.add("sitenav-shadow");
    }

  }

}
