import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/service/appconfig.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/footer/footer.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/footer/footer.component.css']

})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(){

  }
}
