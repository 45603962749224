import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppConfigService } from './service/appconfig.service';
import { EnvironmentConfigService } from './service/EnvironmentConfig.service';
export function app_init(appConfigService: AppConfigService) {
  return () => appConfigService.appInit();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: app_init,
      deps: [AppConfigService,EnvironmentConfigService],
      multi: true,
    },
  ],
})
export class AppConfigModule {}
