import { Injectable } from '@angular/core';
import { AppConfigService } from '../service/appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class SearchUserService {

  constructor(private appConfigService: AppConfigService) { }

  getMyCuiObj() {
    return this.appConfigService.cuiObj;
  }

  getMyPegasusCuiObj() {
    return this.appConfigService.pegasusCuiObj;
  }
  fetchCurrentUser(){
    return this.getMyCuiObj().getPerson(
      { personId: this.appConfigService.authInfo.cuid }
      )
  }

  countPersons(searchKeyword:any,searchParam:any){
    return this.getMyPegasusCuiObj().personsCount({
      qs: [['searchKeyword', searchKeyword],
      ['searchParam', searchParam]
      ]
    })
  }

  searchPersons(searchKeyword:any,searchParam:any,numberOfRows:any,numberOfPages:any){
    return this.getMyPegasusCuiObj().usersSearch({
      qs: [['searchKeyword', searchKeyword ],
      ['searchParam', searchParam],
      ['pageSize', numberOfRows],
      ['page', numberOfPages],
      ]
    })
  }

  searchPersonsReprocess(searchKeyword:any,searchParam:any,numberOfRows:any,numberOfPages:any){
    return this.getMyPegasusCuiObj().usersSearchReprocess({
      qs: [['searchKeyword', searchKeyword ],
      ['searchParam', searchParam],
      ['pageSize', numberOfRows],
      ['page', numberOfPages],
      ]
    })
  }

  getPersonDetails(cuid:any){
    return this.getMyCuiObj().getPersonByCuid(
      { data: { id: cuid } }
      )
  }

  getOrganization(organizationId:any){
    return this.getMyCuiObj().getOrganization({
       organizationId: organizationId 
      })
  }
  
}