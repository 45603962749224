<nav class="sitenav sticky-top js-sitenav" aria-label="site" style="position: fixed; width:100%" id="navbar"
[ngClass]="{'sitenav-shadow': sharedService.scrolled}">    <div class="container">
        <div class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" target="_blank" href="https://www.opentext.com/">
                <svg class="sitenav-logo" width="127" height="24" viewBox="0 0 244 46" aria-label="OpenText logo">
                    <path
                        d="m32.8 21c0 7.4-4.4 15.4-16 15.4-8.4 0-16.1-4.6-16.1-15.4 0-8.9 5.8-15.8 17.2-15.2 12.2.7 14.9 9.9 14.9 15.2zm-21.7-5.6c-1.1 1.6-1.5 3.6-1.5 5.6 0 4.5 2.3 8.6 7.2 8.6 4.8 0 7.1-3.8 7.1-8.2 0-3.2-.8-5.6-2.5-7.1-1.8-1.6-3.9-1.7-5.2-1.6-2.4.1-3.9.9-5.1 2.7zm100-6.3c.9-.9 1.4-1.6 3-2.3 1.4-.6 3.3-1 5.5-1 1.8 0 3.8.3 5.3 1.1 3.1 1.6 4 4.2 4 8.8v20h-8.9v-16.5c0-2.6-.1-3.6-.4-4.4-.7-1.6-2.2-2.2-4-2.2-4.6 0-4.6 3.6-4.6 7.3v15.8h-8.8v-29.2h8.9zm-13.1 19c-.8 1.7-3.4 8.3-14.9 8.3-8.9 0-15.1-5.3-15.1-14.9 0-7 3.6-15.7 15.4-15.7 1.8 0 6.9-.2 10.8 3.8 3.9 4.1 4.1 9.8 4.2 13.1h-21.5c0 3.6 2.1 7.3 6.9 7.3s6.5-3.1 7.6-5.1zm-8.8-10.8c-.2-1.2-.4-2.9-1.7-4.1-1.1-1.1-2.8-1.6-4.3-1.6-2.1 0-3.6 1-4.5 1.9-1.2 1.3-1.5 2.6-1.8 3.9zm131.5-4.7h5.8v-6h-5.8v-6.2h-8.8v6.1h-1.4l-4 6h5.4v13.9c0 2.8.1 4.9 1.3 6.6 1.9 2.7 5.2 2.9 8.3 2.9 1.6 0 2.8-.2 4.6-.5v-6.7l-3.1.1c-2.4 0-2.4-1.5-2.3-3.3zm-87.6-12.2h8.8v6.1h9.1l-4 6h-5.2v13c-.1 1.8-.1 3.3 2.3 3.3l3.1-.1v6.7c-1.9.3-3 .5-4.6.5-3 0-6.4-.2-8.3-2.9-1.2-1.7-1.3-3.8-1.3-6.6v-26zm101.5 12.2h-1.1v-5.2h-2v-.9h5v.9h-2v5.2zm8.5 0h-1.1v-3.7l.1-1.2-.3 1-1.3 4h-1l-1.3-4-.3-1 .1 1.2v3.7h-1v-6h1.5l1.5 4.8 1.5-4.8h1.5v6z"></path>
                    <path d="m178.6 28.1-1.7 2.5c-1.6 2.2-4.5 5.7-13 5.7-8.9 0-14.8-5.3-14.8-14.9 0-7 3.6-15.7 15.4-15.7 1.8 0 6.9-.2 10.8 3.8 3.9 4.1 4.1 9.8 4.2 13.1h-21.4c-.1 3.6 1.8 7.3 6.5 7.3 4.8 0 6.2-3.1 7.3-5.1zm-8.2-10.8c-.2-1.2-.4-2.9-1.7-4.1-1.1-1.1-2.8-1.6-4.3-1.6-2.1 0-3.6 1-4.5 1.9-1.2 1.3-1.5 2.6-1.8 3.9z"></path>
                    <path d="m208.7 35.7-11-15.3 9.1-13.8h-10l-4.3 6.5-4.7-6.5h-10l9.9 13.8-10.2 15.3h10l5.3-8.1 5.8 8.1zm-163.8-26.6c.8-1.3 3.5-3.3 7.8-3.3 7.3 0 12.5 5.4 12.5 15.1 0 6-2.2 15.4-12.9 15.4-3.8 0-6.8-2.1-7.5-3.3v12.2h-8.8v-38.7h8.9zm6 3.4c-1.6 0-3.3.6-4.5 2.2-1.3 1.5-1.8 3.8-1.8 6.4 0 3.4 1 5.5 2.1 6.7 1 1.1 2.5 1.8 4 1.8 4.3 0 6.2-4.4 6.2-8.7 0-3.6-1.1-7.4-4.7-8.2-.5-.1-.9-.2-1.3-.2z"></path>
                </svg>
            </a>

            <div class="sitenav-login-dropdown sitenav-ui-mobile">
                <button id="sitenav-toggler-2" class="sitenav-login2-btn btn btn-sm collapsed" type="button" (click)="toggleMyAccount()" aria-controls="sitenav-collapse-2" aria-expanded="false" aria-label="Toggle login navigation">
                    <svg class="btn-icon btn-icon-first" width="18" height="20" viewBox="0 0 18 20" fill="none" fill-rule="evenodd" clip-rule="evenodd" aria-hidden="true">
                        <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" fill="currentColor"></path>
                        <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" fill="currentColor"></path>
                        <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                        <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path></svg>
                    <span class="sitenav-login-text text-truncate" title="My Account" translate>my-account</span>
                </button>
            </div>

            <a class="sitenav-contact sitenav-ui-mobile btn btn-primary btn-sm" target="_blank"  href="https://www.opentext.com/contact" translate>contact</a>

            <a class="sitenav-search sitenav-ui-mobile btn btn-sm" target="_blank"  href="https://searchopentext.com/">
                <svg width="20" height="20" viewBox="0 0 24 24" role="img" fill="none" stroke="currentColor" stroke-width="1.5" aria-hidden="true" focusable="false">
                    <path d="M23.2 23.2L16.8 16.8M10.4 20C5.09812 20 0.800049 15.702 0.800049 10.4C0.800049 5.09812 5.09812 0.800049 10.4 0.800049C15.702 0.800049 20 5.09812 20 10.4C20 15.702 15.702 20 10.4 20Z"></path>
                </svg>
                <span class="sr-only">Search OpenText</span>
            </a>

            <button id="sitenav-toggler" class="navbar-toggler" [ngClass]="isMenuCollapsed ? 'collapsed':''" type="button" (click)="toggleMenu()" aria-controls="sitenav-collapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-line"></span>
                <span class="navbar-toggler-line"></span>
                <span class="navbar-toggler-line"></span>
            </button>

            <div id="sitenav-collapse" class="navbar-collapse collapse navbar-nav-scroll" [ngbCollapse]="isMenuCollapsed" >
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a id="sitenav-solutions" class="nav-link"  target="_blank" href="https://www.opentext.com/solutions" translate>solutions</a>
                    </li>
                    <li class="nav-item">
                        <a id="sitenav-products" class="nav-link"  target="_blank" href="https://www.opentext.com/products/listing" translate>products</a>
                    </li>
                    <li class="nav-item">
                        <a id="sitenav-services-support" class="nav-link text-nowrap" target="_blank"  href="https://www.opentext.com/customer-stories" translate>services-support</a>
                    </li>
                    <li class="nav-item">
                        <a id="sitenav-partners" class="nav-link"  target="_blank" href="https://www.opentext.com/partners/find-an-opentext-partner" translate>partners</a>
                    </li>
                    <li class="nav-item">
                        <a id="sitenav-resources" class="nav-link"  target="_blank" href="https://www.opentext.com/customer-stories" translate>resources</a>
                    </li>
                    <li class="sitenav-navbar-search nav-item">
                        <a class="nav-link"  target="_blank" href="https://searchopentext.com/">
                            <svg width="20" height="20" viewBox="0 0 24 24" role="img" fill="none" stroke="currentColor" stroke-width="1.5" aria-hidden="true" focusable="false">
                                <path d="M23.2 23.2L16.8 16.8M10.4 20C5.09812 20 0.800049 15.702 0.800049 10.4C0.800049 5.09812 5.09812 0.800049 10.4 0.800049C15.702 0.800049 20 5.09812 20 10.4C20 15.702 15.702 20 10.4 20Z"></path>
                            </svg>
                            <span class="sr-only">Search OpenText</span>
                        </a>
                    </li>
                </ul>

                <a class="sitenav-navbar-contact btn btn-primary btn-sm" target="_blank" href="https://www.opentext.com/contact" translate>contact</a>
            </div>

            <div *ngIf="!isLoggedin" id="sitenav-collapse-2" class="sitenav-login-navbar navbar-collapse collapse navbar-nav-scroll" [ngbCollapse]="isMyAccountCollapsed">
                <ul class="navbar-nav">
                    <li class="sitenav-login-dropdown sitenav-dropdown nav-item dropdown">
                        <button id="sitenav-login-button" class="sitenav-login2-btn nav-link dropdown-toggle btn btn-sm" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <svg class="btn-icon btn-icon-first" width="18" height="20" viewBox="0 0 18 20" fill="none" fill-rule="evenodd" clip-rule="evenodd" aria-hidden="true">
                                <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" fill="currentColor"></path>
                                <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" fill="currentColor"></path>
                                <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                                <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                            </svg>
                            <span class="sitenav-login-text text-truncate" title="My Account" translate>my-account</span>
                        </button>
                        <ul class="sitenav-login-navbar-dropdown-menu sitenav-dropdown-menu dropdown-menu collapse" aria-labelledby="sitenav-login-button">
                            <li>
                                <a class="dropdown-item" href="{{originUri}}" translate>log-in</a>
                            </li>
                            <li>
                                <a class="dropdown-item"  target="_blank" href="https://developer.opentext.com/" translate>developer</a>
                            </li>
                            <li>
                                <a class="dropdown-item"  target="_blank" href="https://epay.opentext.com/?otdslogin" translate>pay-my-bill</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div *ngIf="isLoggedin" id="sitenav-collapse-2" class="sitenav-login-navbar navbar-collapse collapse navbar-nav-scroll" [ngbCollapse]="isMyAccountCollapsed">
                <ul class="navbar-nav">
                    <li class="sitenav-login-dropdown sitenav-dropdown nav-item dropdown">
                        <button id="sitenav-login-button" class="sitenav-login2-btn nav-link dropdown-toggle btn btn-sm" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <svg class="btn-icon btn-icon-first" width="18" height="20" viewBox="0 0 18 20" fill="none" fill-rule="evenodd" clip-rule="evenodd" aria-hidden="true">
                                <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" fill="currentColor"></path>
                                <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" fill="currentColor"></path>
                                <path d="M12.6 5.19775C12.6 7.18485 10.9884 8.7954 9 8.7954C7.0116 8.7954 5.4 7.18485 5.4 5.19775C5.4 3.21065 7.0116 1.6001 9 1.6001C10.9884 1.6001 12.6 3.21065 12.6 5.19775Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                                <path d="M15.75 18.3892H2.25C2.25 17.5371 2.25 15.9765 2.25 15.2425C2.25 13.2542 3.86178 11.6431 5.85 11.6431H12.15C14.1382 11.6431 15.75 13.2542 15.75 15.2425C15.75 15.9765 15.75 17.5371 15.75 18.3892Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                            </svg>
                            <span class="sitenav-login-text text-truncate" title="{{username}}">{{username}}</span>
                        </button>
                        <ul class="sitenav-login-navbar-dropdown-menu sitenav-dropdown-menu dropdown-menu collapse" aria-labelledby="sitenav-login-button">
                            <li>
                                <a class="dropdown-item" (click)="goToProfile()" style="cursor: pointer;" translate>manage-account</a>
                            </li>
                            <li>
                                <a class="dropdown-item" target="_blank" href="https://developer.opentext.com/" translate>developer</a>
                            </li>
                            <li>
                                <a class="dropdown-item"  target="_blank" href="https://epay.opentext.com/?otdslogin" translate>pay-my-bill</a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="logOut()" style="cursor: pointer;" translate>log-out</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <a class="sitenav-contact sitenav-ui-desktop btn btn-primary btn-sm" target="_blank" href="https://www.opentext.com/contact" translate>contact</a>
        </div>
    </div>
</nav>
