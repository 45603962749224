import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PackageService } from '../model/packageServices.model';

import { AppConfigService } from '../service/appconfig.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { personFavorites } from '../model/personFavorites.model';
import { AlertBoxService } from '../alert-box/alert-box.service';
import { SharedService } from '../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationStrategy } from '@angular/common';
import { Router,NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-my-application',
  templateUrl: './my-application.component.html',
  styleUrls: ['./my-application.component.css']
  // templateUrl: '../../'+environment.path+'my-application/my-application.component.html',
  // styleUrls: ['../../'+environment.path+'my-application/my-application.component.css']
})
export class MyApplicationComponent implements OnInit {

  searchForm: FormGroup = new FormGroup({});
  totalCount: number = 0;
  searchedValue: string | undefined;
  tabelData: PackageService[] = [];
  paginatedTabelData: PackageService[] = [];
  personFavorite: personFavorites[] = [];
  cardsPerPage: number = 9;
  currentPage: number = 1;
  favoriteSpinner: boolean = false;
  appSpinner: boolean = false;
  noResultsFound: boolean = false;

  constructor(private appConfigService: AppConfigService,
    private location: LocationStrategy,
    private router: Router, 
    private fb: FormBuilder,
    private alertBoxService: AlertBoxService,
    private translate: TranslateService) {
    this.searchForm = this.fb.group({
      searchKeyword: ['',Validators.required]
    });
    history.pushState(null, '', window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      this.router.navigateByUrl(this.router.url);
      this.alertBoxService.show("back-button-disabled", { classname: 'alert-danger' });
    });
  }

  ngOnInit(): void {
    /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
    /* this.translate.get('myapplications-header-title').subscribe((text:string) => this.sharedService.setHeaderTitle(text));
    this.translate.get('myapplications-header-subtitle').subscribe((text:string) => this.sharedService.setHeaderSubTitle(text)); */

   // this.reloadPage();

    this.appSpinner = true;
    this.fetchFavorite();
    const id = document.getElementById('searchKeyword');
    this.searchForm.controls["searchKeyword"].valueChanges.subscribe(x => {
      if (this.searchForm.controls['searchKeyword'].value === '') {
        this.appSpinner = true;
        this.tabelData = [];
        this.fetchFavorite();
      }
    })
    // setTimeout(()=>{                     
      this.removeHeaderInformation();
    // });
  }

  removeHeaderInformation(){
    this.router.navigate([this.location.path().split('?')[0]], {
      queryParams: {
        'cuid': null,
        'xsrfToken': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  reloadPage(){
    const navigationExtras: NavigationExtras = {
      queryParams: null,
    };
    this.router.navigate([], navigationExtras);
  }
  onSubmit() {
    this.paginatedTabelData = []
    this.searchedValue = this.searchForm.controls['searchKeyword'].value;
    let test: PackageService[] = JSON.parse(sessionStorage.getItem('bulkLodedApplication') || '[]');
    this.tabelData = this.setTableData(test);
    let keyword: string = this.searchedValue || '';
    this.tabelData = this.tabelData.filter((application: PackageService) => application.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));
    if (this.tabelData.length === 0) {
      this.noResultsFound = true;
      this.totalCount = this.tabelData.length;
    } else {
      this.totalCount = this.tabelData.length;
      this.noResultsFound = false;
      this.loadPaginatedTableData();
    }
  }

  fetchFavorite() {
    const promise = new Promise((resolve, reject) => {
      this.appConfigService.cuiObj
        .getPersonFavoritesServices({
          cuid: this.appConfigService.authInfo.cuid
        })
        .then((response: any) => {
          this.personFavorite = response.personFavoriteApps;
          sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(this.personFavorite));
          this.bulkLoadPersonApplications();
          resolve(response);
        })
        .fail(function (err: any) {
          resolve(err);
          reject(err);
        });
    })
    return promise
    // this.httpClient.get("assets/mock-json/getFavorites.json").subscribe((data: any) => {
    //   this.personFavorite = data.personFavoriteApps;
    //   sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(this.personFavorite));
    //   this.bulkLoadPersonApplications();
    // })
  }

  bulkLoadPersonApplications() {
    const promise = new Promise((resolve, reject) => {
      this.appConfigService.cuiObj
        .getPersonApplications({ personId: this.appConfigService.authInfo.cuid })
        .then((response: any) => {
          if (response.length === 0) {
            this.noResultsFound = true;
            this.paginatedTabelData = [];
            this.appSpinner = false;
          } else {
            this.setObjectData(response);
            this.noResultsFound = false;
            this.tabelData = this.setTableData(this.tabelData);
            this.totalCount = this.tabelData.length;
            this.loadPaginatedTableData();
            this.appSpinner = false;
          }
          resolve(response);
        })
        .fail(function (err: any) {
          resolve(err);
          reject(err);
        });
    })
    return promise
    // this.httpClient.get("assets/mock-json/getPersonApplications.json").subscribe((mydata: any) => {
    //   if (mydata.length === 0) {
    //     this.noResultsFound = true;
    //     this.paginatedTabelData = [];
    //     this.appSpinner = false;
    //   } else {
    //     this.setObjectData(mydata);
    //     this.noResultsFound = false;
    //     this.tabelData = this.setTableData(this.tabelData);
    //     this.totalCount = this.tabelData.length;
    //     this.loadPaginatedTableData();
    //     this.appSpinner = false;
    //   }
    // })
  }

  setTableData(tabelData: PackageService[]): PackageService[] {
    let sessionPersonFavorites: personFavorites[] = JSON.parse(sessionStorage.getItem('personpersonFavoritesLocal') || '[]');
    sessionPersonFavorites.forEach((favorite: personFavorites) => {
      const objIndex = tabelData.findIndex((obj => obj.id === favorite.serviceID));
      if (objIndex != -1) {
        tabelData[objIndex].precedence = favorite.precedence;
        tabelData[objIndex].isFavorite = true;
      }
    })
    tabelData.sort(this.sorter());
    return tabelData;
  }

  sorter() {
    return function (a: PackageService, b: PackageService) {
      if (a.precedence === b.precedence) {
        return 0;
      }
      else if (isNaN(a.precedence) || a.precedence === null) {
        return 1;
      }
      else if (isNaN(b.precedence) || b.precedence === null) {
        return -1;
      }
      return a.precedence < b.precedence ? -1 : 1;
    };
  }

  loadPaginatedTableData() {
    this.paginatedTabelData = this.tabelData.slice((this.currentPage - 1) * this.cardsPerPage, this.currentPage * this.cardsPerPage);
  }

  removeFavorite(tabelItem: PackageService, index: number) {
    this.paginatedTabelData[index].spinnerRequired = true;
    const promise = new Promise((resolve, reject) => {
      this.appConfigService.cuiObj
        .deletePersonFavoritesServices({
          cuid: this.appConfigService.authInfo.cuid,
          serviceId: tabelItem.id
        })
        .then((response: any) => {
          let sessionPersonFavorites: personFavorites[] = JSON.parse(sessionStorage.getItem('personpersonFavoritesLocal') || '[]');
          const objIndex = sessionPersonFavorites.findIndex((obj => obj.serviceID === tabelItem.id));
          sessionPersonFavorites.splice(objIndex, 1);
          sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(sessionPersonFavorites));
          const tabelDataIndex = index + (this.cardsPerPage * (this.currentPage - 1));
          this.paginatedTabelData[index].spinnerRequired = false;
          this.tabelData[tabelDataIndex].isFavorite = false;
          this.tabelData[tabelDataIndex].precedence = NaN;
          this.tabelData.sort(this.sorter());
          this.alertBoxService.show('application-removed', { classname: 'alert-success' });
          this.loadPaginatedTableData();
          resolve(response);
        })
        .fail((err: any) => {
          this.paginatedTabelData[index].spinnerRequired = false;
          this.alertBoxService.show('application-error', { classname: 'alert-danger' });
          resolve(err);
          reject(err);
        });
    })
    return promise

    // let sessionPersonFavorites: personFavorites[] = JSON.parse(sessionStorage.getItem('personpersonFavoritesLocal') || '[]');
    // const objIndex = sessionPersonFavorites.findIndex((obj => obj.serviceID === tabelItem.id));
    // sessionPersonFavorites.splice(objIndex, 1);
    // sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(sessionPersonFavorites));
    // const tabelDataIndex = index + (this.cardsPerPage * (this.currentPage - 1));
    // this.paginatedTabelData[index].spinnerRequired = false;
    // this.tabelData[tabelDataIndex].isFavorite = false;
    // this.tabelData[tabelDataIndex].precedence = NaN;
    // this.tabelData.sort(this.sorter());
    // this.alertBoxService.show('Sucessfully Removed Application From Favorites', { classname: 'alert-success' });
    // this.loadPaginatedTableData();
  }

  addFavorite(tabelItem: PackageService, index: number) {
    this.paginatedTabelData[index].spinnerRequired = true;
    const sessionPersonFavorites: personFavorites[] = JSON.parse(sessionStorage.getItem('personpersonFavoritesLocal') || '[]');
    const precedence: number = this.setPrecedence();
    let personFavoriteItem: personFavorites = {
      serviceID: tabelItem.id,
      userID: this.appConfigService.authInfo.cuid,
      type: 'SERVICE_TYPE',
      precedence: precedence
    };
    sessionPersonFavorites.push(personFavoriteItem);
    let personFavorite = {
      personFavoriteApps: sessionPersonFavorites,
    };
    const promise = new Promise((resolve, reject) => {
      this.appConfigService.cuiObj
        .putPersonFavoritesServices({
          cuid: this.appConfigService.authInfo.cuid,
          data: personFavorite
        }
        )
        .then((response: any) => {
          sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(sessionPersonFavorites))
          const tabelDataIndex = index + (this.cardsPerPage * (this.currentPage - 1));
          this.paginatedTabelData[index].spinnerRequired = false;
          this.tabelData[tabelDataIndex].isFavorite = true;
          this.tabelData[tabelDataIndex].precedence = precedence;
          this.favoriteSpinner = false;
          this.tabelData.sort(this.sorter());
          this.loadPaginatedTableData();
          this.alertBoxService.show('application-added', { classname: 'alert-success' });
          resolve(response);
        })
        .fail((err: any) => {
          this.favoriteSpinner = false;
          this.paginatedTabelData[index].spinnerRequired = false;
          this.alertBoxService.show('application-error', { classname: 'alert-danger' });
          resolve(err);
          reject(err);
        });
    })
    return promise
    // sessionStorage.setItem('personpersonFavoritesLocal', JSON.stringify(sessionPersonFavorites))
    // const tabelDataIndex = index + (this.cardsPerPage * (this.currentPage - 1));
    // this.paginatedTabelData[index].spinnerRequired = false;
    // this.tabelData[tabelDataIndex].isFavorite = true;
    // this.tabelData[tabelDataIndex].precedence = precedence;
    // this.favoriteSpinner = false;
    // this.tabelData.sort(this.sorter());
    // this.loadPaginatedTableData();

  }

  setPrecedence() {
    let sessionPersonFavorites: personFavorites[] = JSON.parse(sessionStorage.getItem('personpersonFavoritesLocal') || '[]');
    if (sessionPersonFavorites.length != 0) {
      const maxPrecedence = Math.max.apply(Math, sessionPersonFavorites.map(function (o) { return o.precedence }))
      return maxPrecedence + 1;
    }
    return 1;
  }

  private setObjectData(response: any) {
    let currentLang=this.translate.getDefaultLang();
    this.tabelData = [];
    response.forEach((service: any) => {
      if(service.servicePackage.displayable){
        let name:any[] = service.servicePackage.name;
        let description:any[] = service.servicePackage.description;

      let packageService: PackageService = {
        name: name?.find(x => x.lang === currentLang)?.text || name?.find(x => x.lang === 'en')?.text ,
        discription: description?.find(x => x.lang === currentLang)?.text || description?.find(x => x.lang === 'en')?.text ,
        url: this.setUrl(service),
        id: service.id,
        displayable: service.servicePackage.displayable,
        precedence: NaN,
        isFavorite: false,
        spinnerRequired: false
      };
      this.tabelData.push(packageService);
    }
    });
    sessionStorage.setItem('bulkLodedApplication', JSON.stringify(this.tabelData))
  }

  setUrl(service: any) {
    if (service.hasOwnProperty("mangledUrl")) {
      return service.mangledUrl;
    } else if (service.hasOwnProperty("urls")) {
      return service.urls[0].value;
    }
    return "";
  }

  ngOnDestroy() {
    sessionStorage.clear;
  }

  isTitleTruncated(element: HTMLHeadingElement) {
    return element.clientHeight < element.scrollHeight;
  }
}
