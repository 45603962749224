export class IdpDetails {
    "id": string;
    "realm": string;
    "providerCompanyId": string;
    "entityId" : string;
    "domainCode": string;
    "federationType": string;
    "providerType": string;
    "providerName": string;
    "userAccountLinkEnabled": boolean;
    "userAccountLinkRedirectURL": string;
    "ssoLinkURL": string;
}