import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigService {
  constructor(private http: HttpClient) {}

  private static ENV_KEY = 'ENVIRONMENT';
  private config: any;
  get environment() {
    if (!this.config && sessionStorage.getItem(EnvironmentConfigService.ENV_KEY)) {
      this.config = JSON.parse(sessionStorage.getItem(EnvironmentConfigService.ENV_KEY) || '{}');
    } else if (!sessionStorage.getItem(EnvironmentConfigService.ENV_KEY)) {
      throw new Error('Config is not initialized !!');
    }
    return this.config;
  }

  set environment(config: any) {
    this.config = config;
  }

  public loadEnvironment(configPath: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(configPath).subscribe((configData: any) => {
        configData.realm = configData.originUri.split('.')[0];
        configData.solutionInstancesUrl = configData.solutionInstancesUrl;
        configData.serviceUrl = configData.solutionInstancesUrl ;
        //configData.adminSpaUri = configData.adminSpaUri;
        configData.logoutUrl = configData.logoutUrl;
        configData.vistorPasswordPolicyId = configData.vistorPasswordPolicyId;
        configData.pegasusServiceUrl = 'https://' + configData.pegasusServiceUrl;
        sessionStorage.setItem(EnvironmentConfigService.ENV_KEY, JSON.stringify(configData));
        resolve(configData);
      });
    });
  }
}
