import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AlertBoxService } from 'src/app/alert-box/alert-box.service';
import { getErrorMessage } from 'src/app/shared/error-messages';
import { passwordPolicyValidation } from 'src/app/shared/password-validator';
import { PasswordAccount } from '../../model/passwordAccount.model';
import { AppConfigService } from '../../service/appconfig.service';
import { ManageProfileService } from '../manage-profile.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css'],
  // templateUrl: '../../../'+environment.path+'manage-profile/update-password/update-password.component.html',
  // styleUrls: ['../../../'+environment.path+'manage-profile/update-password/update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  @Output() closeClicked: EventEmitter<any> = new EventEmitter();

   updatePasswordForm: FormGroup = new FormGroup({});
   passwordPolicies: any;
   
  maskUnmaskPassword: boolean = false;
  maskUnmaskConfirmPassword: boolean = false;
  maskUnmaskCurrentPassword: boolean = false;
  
  passwordType: String = 'password';
  passwordTypeConfirmPassword: String = 'password';
  passwordTypeCurrentPassword: String = 'password';
  fadeModal = false;message!: String
  submitSpinner: boolean=false;

  constructor(private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private fb: FormBuilder,
    private manageProfileService: ManageProfileService,
    private alertService: AlertBoxService) {
  }

  ngOnInit(): void {
    document.getElementById("update-password")?.click();
    this.loadReactiveForm();
    this.fecthPasswordAccount();
  }


  fecthPasswordAccount() {
    // this.httpClient.get("assets/passwordAccount.json").subscribe((data: any) => {
    //   this.patchFormWithPasswordAccount(data);
    //   this.fetchPasswordPolicy(data);
    // })
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.fetchCurrentUserPasswordAccount()
        .then((response: any) => {
          this.patchFormWithPasswordAccount(response);
          this.fetchPasswordPolicy(response);
          resolve(response);
        })
        .fail( (err: any) => {
          this.message = getErrorMessage(err?.responseJSON, 'can-not-fetch-password-policy');
          this.alertService.show(String(this.message), { classname: 'alert-danger' });
        });
    })
    return promise
  }

  private patchFormWithPasswordAccount(data: any) {
    this.updatePasswordForm.patchValue({
      version: data.version,
      username: data.username,
      passwordPolicy: {
        id: data.passwordPolicy.id,
        type: data.passwordPolicy.type,
        realm: data.passwordPolicy.realm
      },
      authenticationPolicy: {
        id: data.authenticationPolicy.id,
        type: data.authenticationPolicy.type,
        realm: data.authenticationPolicy.realm,
      },
    });
  }

  fetchPasswordPolicy(passwordAccount: PasswordAccount) {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.fetchPasswordPolicyById(passwordAccount.passwordPolicy.id)
        .then((response: any) => {
          this.passwordPolicies = response;
          this.updatePasswordForm.get('password')?.setValidators([
            passwordPolicyValidation(this.passwordPolicies.rules, this.updatePasswordForm)
          ]);
          resolve(response);
        })
        .fail((err: any) => {
          this.message = getErrorMessage(err?.responseJSON, 'can-not-fetch-password-policy');
          this.alertService.show(String(this.message), { classname: 'alert-danger' });
        
        });

    });
    return promise;
  }

  ConfirmedValidator() {
    return (formGroup: FormGroup) => {
     
      const control = formGroup.controls['password'];
      const matchingControl = formGroup.controls['confirmPassword'];
      if (matchingControl?.errors && !matchingControl?.errors.confirmedValidator) {
        return;
      }
      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({
          confirmedValidator: true,
          'message': 'password-not-matching'
        });
      } else {
        matchingControl?.setErrors(null);
      
      }


    }

  }
  
  loadReactiveForm() {
    this.updatePasswordForm = this.fb.group({
      version: [''],
      username: [''],
      password: ['', [Validators.required]],
      currentPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      passwordPolicy: this.fb.group({
        id: [''],
        type: [''],
        realm: [''],
      }),
      authenticationPolicy: this.fb.group({
        id: [''],
        type: [''],
        realm: [''],
      }),
    }, {
      validator: this.ConfirmedValidator()
    });

  }

  onSubmitPassword() {
    if(this.updatePasswordForm.valid){
      this.submitSpinner=true;
      this.updatePasswordForm.removeControl('confirmPassword');
      const promise = new Promise((resolve, reject) => {
        this.manageProfileService.updateCurrentPersonPassword(this.updatePasswordForm.value)
          .then((response: any) => {
            this.submitSpinner=false;
            document.getElementById('password-modal-fade')?.click();
            this.alertService.show('update-password-success', { classname: 'alert-success'});
          })
          .fail((err: any) => {
            this.submitSpinner=false;
            document.getElementById('password-modal-fade')?.click();
            this.message=getErrorMessage(err.responseJSON,'update-password-error');
            this.alertService.show(String(this.message), { classname: 'alert-danger'});
          });
      })
    } else {
      this.updatePasswordForm.markAllAsTouched();
    }
  }



  sendNotification() {
    this.closeClicked.emit("close");
  }

  maskPassword() {
    if (this.maskUnmaskPassword) {
      this.maskUnmaskPassword = false;
      this.passwordType= 'password';
    }
    else {
      this.maskUnmaskPassword = true;
      this.passwordType = 'text';
    }
  }

  maskCurrentPassword() {
    if (this.maskUnmaskCurrentPassword) {
      this.maskUnmaskCurrentPassword = false;
      this.passwordTypeCurrentPassword = 'password';
    }
    else {
      this.maskUnmaskCurrentPassword = true;
      this.passwordTypeCurrentPassword = 'text';
    }
  }


  maskConfirmPassword() {
    if (this.maskUnmaskConfirmPassword) {
      this.maskUnmaskConfirmPassword = false;
      this.passwordTypeConfirmPassword = 'password';
    }
    else {
      this.maskUnmaskConfirmPassword = true;
      this.passwordTypeConfirmPassword = 'text';
    }
  }

}
