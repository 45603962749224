import { EventEmitter, Injectable, Output } from '@angular/core';
import { SearchTable } from '../model/searchTable.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() syncStatusUpdated = new EventEmitter<SearchTable>();
  /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
  /* headerTitle:string = '';
  headerSubtitle:string = ''; */
  scrolled: boolean = false;
  //searchCuid!: string;

  updateSyncStatus(tabledata: SearchTable) {
    this.syncStatusUpdated.emit(tabledata);
  }

  @Output() updatedUsername = new EventEmitter();

  updateUsername(username: String) {
    this.updatedUsername.emit(username);
  }

  profilePage = new Subject<boolean>();

  setProfilePage(value:boolean){
    this.profilePage.next(value);
  }

  getProfilePageSubscription(){
    return this.profilePage.asObservable();
  }

  // setSearchCuid(cuid:string){
  //   this.searchCuid=cuid
  // }
  // getSearchCuid(){
  //   return this.searchCuid;
  // }

  /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
  /* setHeaderTitle(title:string) {
    this.headerTitle = title;
  }

  getHeaderTitle(){
    return this.headerTitle;
  }

  setHeaderSubTitle(subTitle:string) {
    this.headerSubtitle = subTitle;
  }

  getHeaderSubTitle(){
    return this.headerSubtitle;
  } */
}
