import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertBoxService {
  alerts: any[] = [];
  constructor() { }
  show(message: string | TemplateRef<any>, options: any = {}) {
    const found = this.alerts.some(el => el.message === message);
    if (!found) {
      this.alerts.push({ message, ...options });
      this.alerts.reverse();
      setTimeout(() => {
        this.alerts.pop()
      }, 6000);
    }
  }

  remove(alert: any) {
    this.alerts = this.alerts.filter(t => t !== alert);
  }

}