<div [class]="alert.classname"
    [ngClass]="{'alert-danger' : !isTemplate(alert) , 'alert-warning' : isTemplate(alert)}"
    class="alert alert-alt alert-dismissible shadow fade show" dismiss-on-timeout="6000" close="closeAlert()"
    role="alert" *ngFor="let alert of alertservice.alerts;let i = index" (hidden)="alertservice.remove(alert)"
    [ngStyle]="{'margin-top': i==0 ? '100px' : '10px' }">
    <ng-container *ngIf="!isTemplate(alert)">
        <p class="mb-0"><strong translate>{{alert.message}}</strong></p>
    </ng-container>
    <ng-container *ngIf="isTemplate(alert)">
        <ng-container *ngTemplateOutlet="alert.message">
        </ng-container>
    </ng-container>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.601562 0.599609L13.4016 13.3996M0.601562 13.3996L13.4016 0.599609" stroke="#0066FF"
                stroke-width="1.5" />
        </svg>
    </button>
</div>