<button class="float-end" id="update-mfa" href="" type="button" hidden data-target="#update-mfa-modal"
    data-backdrop="static" data-toggle="modal">Open</button>


<div id="update-mfa-modal" class="modal fade in" tabindex="-1" aria-hidden="false" aria-labelledby="my-modal">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content rounded-0"  >
            <div class="modal-header">
                <h5 id="update-mfa-modal" class="modal-title" translate>change-mfa-mode</h5>
                <button class="close" type="button" (click)="sendNotification()" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="updateMfaForm" class="ot-form">
                        <div class="row container ">
                            <div class="col-lg-6 col-12">
                                <label for="preferredMode" class="ot-form__label lable-bold req" translate>preferred-mode</label>
                                <select id="preferredMode" formControlName="preferredMode"
                                    class=" form-control js-onchange-submit">
                                    <option class="dropdown-item" value="" disabled selected hidden translate>select
                                    </option>
                                    <option class="dropdown-item" *ngFor="let mode of preferredModes | keyvalue"
                                        value={{mode.value}} translate>{{mode.key}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <!-- email mode selected  -->
                        <div class="row container" [hidden]="!this.ifEmailVisible">
                            <div class="col-lg-6 col-12 form-group" style="margin-bottom: 10px;">
                                <label for="email" class="ot-form__label req lable-bold" translate>email</label>
                                <input type="email" class="form-control" name="email" formControlName="email">
                                <div style="height: auto">
                                    <div *ngIf="updateMfaForm.get('email')?.invalid  && updateMfaForm.get('email')?.touched  "
                                        class="help-block">
                                        <!-- <app-error-message [control2]="'email-required'"></app-error-message> -->
                                        <p class="email-message"
                                            *ngIf="updateMfaForm.get('email')?.errors?.message === 'empty'">
                                            <small translate>email-empty</small>
                                        </p>
                                        <p class="email-message"
                                            *ngIf="updateMfaForm.get('email')?.errors?.message === 'format'">
                                            <small translate>email-required</small>
                                        </p>
                                    </div>
                                    <p style="margin-bottom: 0px;" *ngIf="updateMfaForm.get('email')?.valid"><small
                                            translate>verifaction-code-will-be-sent-to-mail</small></p>
                                </div>
                            </div>
                            <div *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                            this.modeUpdateRequired" class="col-lg-3 col-6 custom-button" >
                                <button class="btn btn-success" type="button"
                                style="padding-left: 15px !important;text-align: center;width: max-content;" translate>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                        width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                    </svg>
                                    &nbsp;&nbsp;verified</button>
                            </div>
                            <div *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                            this.modeUpdateRequired"  class="col-lg-3 col-6 custom-button" >
                                <button class="btn btn-outline-primary" type="button" (click)="removeMode('EMAIL')"
                                    translate>remove</button>
                            </div>

                            <div *ngIf="!this.modeUpdateRequired" class="col-lg-6 col-12 custom-button">
                                <button class="btn btn-primary" type="button"  style="margin-bottom: 5px;"
                                    [disabled]="!updateMfaForm.get('email')?.valid"
                                    (click)="validatePassCode('EMAIL'); this.emailButton='resend-passcode'" translate>{{this.emailButton | translate}} &nbsp;
                                    <app-spinner [enabled]="submitSpinner" [size]="'23px'" [class]="'text-light'">
                                    </app-spinner>
                                </button>
                            </div>
                            <!-- </div> -->
                        </div>

                        <!--  phone or sms mode selected  -->

                        <div class="row container" [hidden]="!this.ifPhoneVisible ">
                            <div class="col-lg-6 col-12 form-group" style="margin-bottom: 10px;">
                                <label for="main" class="ot-form__label req lable-bold" translate>work-phone</label>
                                <input type="main" class="form-control" name="main" formControlName="main">
                                <div style="height: auto">
                                    <div *ngIf="updateMfaForm.get('main')?.invalid  && updateMfaForm.get('main')?.touched  || 
                                    updateMfaForm.get('main')?.errors?.message === 'Invalid Phone Number'"
                                        class="help-block">
                                        <app-error-message [control2]="'phone-required'"></app-error-message>
                                    </div>
                                    <p style="margin-bottom: 0px;"
                                        *ngIf="updateMfaForm.get('main')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'">
                                        <small translate>verifaction-code-will-be-sent-as-text-message</small>
                                    </p>
                                    <p style="margin-bottom: 0px;"
                                        *ngIf="updateMfaForm.get('main')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'">
                                        <small translate>verifaction-code-will-be-sent-by-phone-call</small>
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                             && this.modeUpdateRequiredMain" class="col-lg-3 col-6 custom-button">
                                <button class="btn btn-success" type="button"
                                style="padding-left: 15px !important;text-align: center;width: max-content;" translate>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                        width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                    </svg>
                                    &nbsp;&nbsp;verified</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                             && this.modeUpdateRequiredMain" class="col-lg-3 col-6 custom-button" >
                                <button class="btn btn-outline-primary" type="button" (click)="removeMode('MAIN')"
                                    translate>remove</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                             && this.modeUpdateRequiredMain" class="col-lg-3 col-6 custom-button" 
                                style="left: 12px;">
                                <button class="btn btn-success" type="button"
                                style="padding-left: 15px !important;text-align: center;width: max-content;" translate>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                        width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                    </svg>
                                    &nbsp;&nbsp;verified</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                             && this.modeUpdateRequiredMain"  class="col-lg-3 col-6 custom-button" >
                                <button class="btn btn-outline-primary" type="button" (click)="removeMode('MAIN')"
                                    translate>remove</button>
                            </div>

                            <div *ngIf="!this.modeUpdateRequiredMain" class="col-lg-6 col-12 custom-button">
                                <button class="btn btn-primary" type="button" style="margin-bottom: 5px;"
                                    (click)="validatePassCode('MAIN'); this.mainPhoneButton ='resend-passcode'"
                                    [disabled]="!updateMfaForm.get('main')?.valid" translate>{{this.mainPhoneButton |
                                    translate}} &nbsp;
                                    <app-spinner [enabled]="submitSpinner" [size]="'23px'" [class]="'text-light'">
                                    </app-spinner>
                                </button>
                            </div>
                        </div>

                        <div class="row container" [hidden]="!this.ifPhoneVisible">
                            <div class="col-lg-6 col-12 form-group" style="margin-bottom: 10px;">
                                <label for="mobile" class="ot-form__label req lable-bold" translate>mobile</label>
                                <input type="mobile" class="form-control" name="mobile" formControlName="mobile">
                                <div style="height: auto">
                                    <div *ngIf="this.updateMfaForm.get('mobile')?.invalid  && this.updateMfaForm.get('mobile')?.touched || 
                                    this.updateMfaForm.get('mobile')?.errors?.message === 'Invalid Phone Number'"
                                        class="help-block">
                                        <app-error-message [control2]="'phone-required'"></app-error-message>
                                    </div>
                                    <p style="margin-bottom: 0px;"
                                        *ngIf="updateMfaForm.get('mobile')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'">
                                        <small translate>verifaction-code-will-be-sent-as-text-message</small>
                                    </p>
                                    <p style="margin-bottom: 0px;"
                                        *ngIf="updateMfaForm.get('mobile')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'">
                                        <small translate>verifaction-code-will-be-sent-by-phone-call</small>
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE' 
                             && this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                <button class="btn btn-success" type="button"
                                style="padding-left: 15px !important;text-align: center;width: max-content;" translate>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                        width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                    </svg>
                                    &nbsp;&nbsp;verified</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'&&
                             this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE'  && 
                             this.modeUpdateRequiredMobile"  class="col-lg-3 col-6 custom-button">
                                <button class="btn btn-outline-primary" type="button" (click)="removeMode('MOBILE')"
                                    translate>remove</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE' 
                             && this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                <button class="btn btn-success" type="button"
                                style="padding-left: 15px !important;text-align: center;width: max-content;" translate>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                        width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                    </svg>
                                    &nbsp;&nbsp;verified</button>
                            </div>
                            <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'&&
                             this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE'  && 
                             this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                <button class="btn btn-outline-primary" type="button" (click)="removeMode('MOBILE')"
                                    translate>remove</button>
                            </div>

                            <div *ngIf="!this.modeUpdateRequiredMobile" class="col-lg-6 col-12 custom-button">
                                <button class="btn btn-primary" type="button" style="margin-bottom: 5px;"
                                    [disabled]="!updateMfaForm.get('mobile')?.valid"
                                    aria-placeholder="Enter your 6-digit Passcode"
                                    (click)="validatePassCode('MOBILE'); this.mobilePhoneButton='resend-passcode'"
                                    translate>{{this.mobilePhoneButton | translate}}
                                    &nbsp; <app-spinner [enabled]="submitSpinnerMobile" [size]="'23px'"
                                        [class]="'text-light'">
                                    </app-spinner></button>
                            </div>
                        </div>

                        <!-- GA mode selected  -->



                        <div class="row container" [hidden]="!this.ifGaVisible">
                            <div *ngIf=!this.isGAVerified class="col-6 form-group" style="margin-bottom: 10px;">
                                <div id="gaStep1">
                                    <p class="content-step-1" style="font-weight: bold;margin-bottom: 12px;" translate>
                                        <span class="content-step-1" translate>step-1 </span>
                                        install-google-authenticator
                                    </p>
                                    <p style="margin-bottom: 1rem; text-align: left;" translate>
                                        download-the-app-for-android-and-iOS-using-the-buttons-below</p>
                                    <div><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                            target="_blank"><img class="play-store" style="width: 150px "
                                                src="assets/images/google-play-badge.png" alt="google play store"
                                                style="margin-right: 1rem;"></a><a
                                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                            target="_blank"><img src="assets/images/apple-store-badge.svg"
                                                alt="app store"></a></div>
                                </div>
                                <p class="content-step-1" style="font-weight: bold;margin-bottom: 12px;" translate><span
                                        class="content-step-1" translate>step-2 </span>configure </p>
                                <p style="margin-bottom: 1rem; text-align: left;" translate>step2-instruction</p>
                            </div>
                            <div *ngIf=!this.isGAVerified class="col-6 form-group"
                                style="margin-bottom: 0px; text-align: center;">
                                <p class="content-step-1" style="font-weight: bold;margin-bottom: 0px;"><span
                                        class="content-step-1" translate>qr-code </span></p>
                                <img class="qr-code" style="width: 250px ; " src={{qrCodeImage}} alt="google play store"
                                    style="margin-right: 1rem;">
                            </div>
                            <div *ngIf=this.isGAVerified style=" margin-left: 15px; ">
                                <p class="gaDeviceName" translate>
                                    device-successfully-registered
                                    <img _ngcontent-lww-c216="" src="assets/images/tickmark.svg" alt=""
                                        class="imageSuccess">
                                </p>
                                <button class="btn btn-primary" type="button" (click)="deleteGoogleAccount()"
                                    translate>remove
                                    &nbsp; <app-spinner [enabled]="removeButton" [size]="'23px'" [class]="'text-light'">
                                    </app-spinner></button>
                            </div>
                        </div>


                        <!-- common for all modes -->

                        <div class="row container"
                            *ngIf="!this.isGAVerified || this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() !== 'GA' ">
                            <div class="col-lg-6 col-12 form-group passcode">
                                <label for="passCode" class="ot-form__label req lable-bold" translate>pass-code</label>
                                <input type="passCode" class="form-control" name="passCode" type="password"
                                    formControlName="passCode">
                                <div style="height: 24px;">
                                    <div *ngIf="this.updateMfaForm.get('passCode')?.invalid  && this.updateMfaForm.get('passCode')?.touched && this.updateMfaForm.get('passCode')?.value === ''" 
                                        class="help-block">
                                        <app-error-message [control2]="'passcode-required'"></app-error-message>
                                    </div>
                                    <div *ngIf="this.updateMfaForm.get('passCode')?.invalid  && this.updateMfaForm.get('passCode')?.dirty && this.updateMfaForm.get('passCode')?.errors?.message === 'invalidPasscode' "
                                        class="help-block">
                                        <app-error-message [control2]="'passcode-incorrect'"></app-error-message>
                                    </div>
                                </div>
                            </div>
                        </div>
                </form>
            </div>

            <div class="modal-footer">
                <button id="update-mode-close" class="btn btn-outline-primary" type="button" data-dismiss="modal"
                    (click)="sendNotification()" translate>close</button>
                <!-- modal on modal pop up -->
                <button class="btn btn-primary" *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() !== 'GA'"
                    type="button" [disabled]="!this.updateMfaForm.get('passCode')?.valid" (click)="validateOTP()"
                    data-toggle="modal" translate>update &nbsp; <app-spinner [enabled]="submitSpinnerModal"
                        [size]="'23px'" [class]="'text-light'">
                    </app-spinner></button>

                <button class="btn btn-primary" *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'GA'"
                    type="button" [disabled]="!this.updateMfaForm.get('passCode')?.valid" (click)="validateOTPGA()"
                    data-toggle="modal" translate>update &nbsp; <app-spinner [enabled]="submitSpinnerModal"
                        [size]="'23px'" [class]="'text-light'">
                    </app-spinner></button>
            </div>
        </div>
    </div>
</div>

<!--warning modal invoked programitically if email is old -->
<button id="warning-modal-id" data-backdrop="static" class="btn btn-primary" type="button" data-target="#warning-modal"
    [hidden]="true" data-toggle="modal"> </button>




<div id="warning-modal" class="modal fade in" tabindex="-1" aria-hidden="false" aria-labelledby="my-modal">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content rounded-0" style="height: auto;">
            <div class="modal-header">

                <h5 id="warning-modal" class="modal-title" translate>
                    <img class="icon" style="color: red; display: inline;"
                        src="assets/images/error_outline_black.svg" />&nbsp;warning
                </h5>
                <button class="close"  (click)="cancelled()" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL'" translate>email-warning
                </p>
                <p *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS' || 
                this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'" translate>
                    phone-warning
                </p>
            </div>

            <div class="modal-footer">
                <button id="warning-cancel" class="btn btn-outline-primary" type="button" data-dismiss="modal"
                    (click)="cancelled()" translate>cancel</button>
                <button class="btn btn-primary" (click)="sendPassCode()" type="button" translate>yes &nbsp; <app-spinner
                        [enabled]="submitSpinnerWarningModal" [size]="'23px'" [class]="'text-light'">
                    </app-spinner></button>
            </div>
        </div>
    </div>
</div>