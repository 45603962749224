<div class="row justify-content-center pt-4">
    <div class="col-12 col-lg-8">
        <div class="card card-primary-top shadow">
            <div class="card-body p-4 p-md-8">
                <img class="img-responsive mb-3 mb-lg-4" src="https://assets.ot.digital/img/opentext-process-remove-circle-bold-ico-72.svg" width="88" height="74" alt="" />
                <h2 translate>not-authorized</h2>
                <p class="card-text text-lg" translate>grant-access-to-resource-denied</p>
                <p class="card-text text-lg" translate>contact-administrator</p>
                <p class="card-text text-lg mt-10"><a class="more more-bold" href="https://www.opentext.com/support/contact/opentext" translate>ot-support</a></p>
            </div>
        </div>
    </div>
</div>
