import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-tooltip',
  templateUrl: './password-tooltip.component.html',
  styleUrls: ['./password-tooltip.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/password-tooltip/password-tooltip.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/password-tooltip/password-tooltip.component.css']
})
export class PasswordTooltipComponent implements OnInit {
  passwordCharacterClass: any;
  passwordLength: any;
  passwordSequence: any;
  passwordUsername: any;
  passwordDisallowedCharacters: any;
  passwordHistory: any;
  passwordLifetime: any;
  
  @Input()
  passwordpolicies:any;

  @Input()
  error:any;

  @Input()
  value:any;

  constructor() { }

  ngOnInit(): void {
    this.loadLabels();
    if(this.value === ''){
       this.error={'invalidPasswordLength':true,
      'invalidPasswordLower':true,
      'invalidPasswordUpper':true,
      'invalidPasswordNumber':true,
      'invalidPasswordSpecial':true};
    }
  }

  loadLabels() {
    for (let i of this.passwordpolicies) {
      if (i.type == "character_class")
        this.passwordCharacterClass = i
      if (i.type == "length")
        this.passwordLength = i
      if (i.type == "sequence")
        this.passwordSequence = i
      if (i.type == "username")
        this.passwordUsername = i
      if (i.type == "disallowed_characters")
        this.passwordDisallowedCharacters = i
      if (i.type == "history")
        this.passwordHistory = i
      if (i.type == "lifetime")
        this.passwordLifetime = i
    }
  }
}
