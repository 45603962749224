import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertBoxService } from 'src/app/alert-box/alert-box.service';
import { ManageProfileService } from '../manage-profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  // templateUrl: '../../../'+environment.path+'manage-profile/reset-password/reset-password.component.html',
  // styleUrls: ['../../../'+environment.path+'manage-profile/reset-password/reset-password.component.css']

})
export class ResetPasswordComponent implements OnInit {

  @Output() closeClicked: EventEmitter<string> = new EventEmitter<string>();
  showResetPasswordContent = false;
  submitSpinnerResetPassword = false;
  firstHalfPassword!: string;
  cuid!: string;
  @Input() personDetails: any;

  constructor(private activatedRoute:ActivatedRoute,
    private manageProfileService:ManageProfileService,
    private alertService: AlertBoxService) {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   if(params['cuid'])
    //     this.cuid = params['cuid'];
    // })
   }

  ngOnInit(): void {
    this.cuid=this.personDetails.id;
    document.getElementById("reset-password")?.click();
  }

  resetPassword(){
    this.submitSpinnerResetPassword = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .resetPersonPassword(this.cuid)
        .then((response: any) => {
          let password = response.password;
          this.firstHalfPassword = password.substring(0, password.indexOf('*'));
          this.submitSpinnerResetPassword = false;
          this.showResetPasswordContent = true;          
          resolve(response);
        })
        .fail((err: any) => {
          this.submitSpinnerResetPassword = false;
          this.alertService.show('Error while resetting user password', { classname: 'alert-danger' });
        });
    })
    return promise
  }

  sendNotification() {
    this.closeClicked.emit("close");
  }
}
