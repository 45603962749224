import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../service/appconfig.service';
import { getErrorMessage } from '../shared/error-messages';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css'],
  // templateUrl: '../../'+environment.path+'email-confirmation/email-confirmation.component.html',
  // styleUrls: ['../../'+environment.path+'email-confirmation/email-confirmation.component.css',]
})
export class EmailConfirmationComponent implements OnInit {
  secret!: String;
  message!: String;
  registration!: String;
  envProperties: any;
  originUri!:String;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private appConfigService: AppConfigService) { }

  ngOnInit(): void {
    /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
    /* this.translate.get('confirmation-header-title').subscribe((text:string) => this.sharedService.setHeaderTitle(text));
    this.translate.get('confirmation-header-subtitle').subscribe((text:string) => this.sharedService.setHeaderSubTitle(text)); */

    this.route.queryParams.subscribe(params => {
      this.secret = params['secret'];
      this.registration = params['registration'];
    })
    this.envProperties = this.appConfigService.envProperties;
    this.originUri=this.envProperties.solutionInstancesUrl;
    if(this.route.toString().includes('email/verification')){
      this.displayUpdateMessage();
    }
    else{
      this.displayMessage();
    }

  }
  
  navigateToMyProfile(){
    this.router.navigate(['/manageProfile']);
    setTimeout(() => { window.location.reload(); }, 100);
  }

  displayUpdateMessage() {
    if(this.secret){
      const promise = new Promise((resolve, reject) => {
      this.appConfigService.cuiObj
      .initiateNonce()
      .then((obj:any) => {
      this.appConfigService.pegasusCuiObj
        .emailUpdateValidation(
          {
            data:
            {
              secret: this.secret
            }
          }
        )
        .then((response: any) => {
          this.message = 'email-verification-successfull';
          resolve(response);
        })
        .fail((err: any) => {
          if(err.responseJSON){
            if(err.responseJSON.apiStatusCode === 'user.verification.invalid.code'){
              this.message = getErrorMessage(err.responseJSON, 'email-verification-invalid-secret');
            }
            if(err.responseJSON.apiStatusCode === 'user.verification.code.verified') {
              this.message = getErrorMessage(err.responseJSON, 'email-verification-verified-secret');
            }
            else if(err.responseJSON.apiStatusCode === 'user.verification.code.expired') {
              this.message = getErrorMessage(err.responseJSON, 'email-verification-expired-secret');
            }
            else{
              this.message = getErrorMessage(err.responseJSON, 'email-verification-invalid-secret');
            }
          }
          else{
            this.message = getErrorMessage(err.responseJSON, 'email-verification-invalid-secret');
          }
        });
    });
  });
  }
  else if(!this.secret) {
    this.message = 'email-verification-empty-secret';
  }
  }

  //display message according to secret validation or status
  displayMessage() {
    if (this.secret) {
      const promise = new Promise((resolve, reject) => {
        this.appConfigService.cuiObj
        .initiateNonce()
        .then((obj:any) => {
        this.appConfigService.pegasusCuiObj
          .emailValidation(
            {
              data:
              {
                secret: this.secret
              }
            }
          )
          .then((response: any) => {
            this.message = 'registration-successfull';
            resolve(response);
          })
          .fail((err: any) => {
              this.message=getErrorMessage(err.responseJSON,'secret-invalid');
          });
      });
    });
    }
    if (this.registration=="pending") {
      this.message = 'registration-successfull-check-mail';
    }
    else if (this.registration=="success") {
      this.message = 'registration-successfull';
    }
    else if(!this.secret) {
      this.message = 'login-to-continue';
    }

  }
}

