<div class="row justify-content-center pt-4">
    <div class="col-12 col-lg-8">
        <div class="card card-primary-top shadow">
            <div class="card-body p-4 p-md-8">
                <app-spinner [enabled]="!message" [size]="'60px'" [class]="'text-primary'"></app-spinner> 
                <img *ngIf="message==='registration-successfull' || message==='registration-successfull-check-mail' || message==='email-verification-successfull'" class="img-responsive mb-3 mb-lg-4" src="https://assets.ot.digital/img/opentext-performance-check-simple-ico.svg" width="88" height="74" alt="" />
                <img *ngIf="message && (message!=='registration-successfull' && message!=='link-validated' &&  message!=='email-verification-successfull' && message!=='registration-successfull-check-mail' && message!=='email-verification-verified-secret')" class="img-responsive mb-3 mb-lg-4" src="https://assets.ot.digital/img/opentext-process-remove-circle-bold-ico-72.svg" width="88" height="74" alt="" />
                <img *ngIf="message==='link-validated'|| message==='email-verification-verified-secret'" class="img-responsive mb-3 mb-lg-4" src="https://assets.ot.digital/img/opentext-protection-alert-triangle-ico-72.svg" width="88" height="74" alt="" />
                <ng-container *ngIf="message==='registration-successfull-check-mail'">
                    <p class="card-text text-lg" translate>
                        registration-successfull-thankyou
                    </p>
                </ng-container>
                <p class="card-text text-lg" translate>{{message}}</p>
                <ng-container *ngIf="message==='registration-successfull-check-mail'">
                    <p class="card-text text-lg" translate>
                        <strong translate>registration-successful-note</strong>
                        registration-successful-check-spam
                    </p>
                </ng-container>
                <p *ngIf="message==='registration-successfull'" class="card-text text-lg mt-10"><a class="more more-bold" href="{{originUri}}" translate>click-here-to-login</a></p>
                <p *ngIf="message==='email-verification-successfull' || message==='email-verification-verified-secret' || message ==='email-verification-invalid-secret'" class="card-text text-lg mt-10"><a class="more more-bold hand-cursor" (click)="navigateToMyProfile()" translate>continue</a></p>
                <p *ngIf="message==='email-verification-expired-secret' || message ==='email-verification-empty-secret'" class="card-text text-lg mt-10"><a class="more more-bold hand-cursor" (click)="navigateToMyProfile()" translate>update-profile</a></p>
            </div>
        </div>
    </div>
</div>