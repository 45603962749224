<div class='popover-content popover-light-left' *ngIf="passwordLength ||passwordCharacterClass !!passwordDisallowedCharacters !!passwordUsername  || passwordSequence">
    <div class='h6 mb-2' translate>passwords-requirements</div>
    <ul class='list-unstyled list-space text-sm m-0 p-0'>
        <li *ngIf="passwordLength">
            <ng-container *ngIf="!error?.invalidPasswordLength">
            <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                    stroke='#156615' />
            </svg>
            {{'password-length'| translate:{min:passwordLength?.min ,max:passwordLength?.max} }}
            </ng-container>
            <ng-container *ngIf="error?.invalidPasswordLength">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                        fill='#B11206' />
                </svg>
                <strong>{{'password-length'| translate:{min:passwordLength?.min ,max:passwordLength?.max} }}</strong>
            </ng-container>
        </li>
        <li *ngIf="passwordCharacterClass?.repeatingCharCount">
            <ng-container *ngIf="!error?.invalidPasswordRepeating">
            <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                    stroke='#156615' />
            </svg>
            {{'password-repeating-character'| translate:{repeatingCharCount:(passwordCharacterClass?.repeatingCharCount)} }}
            </ng-container>
            <ng-container *ngIf="error?.invalidPasswordRepeating">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                        fill='#B11206' />
                </svg>
                <strong>{{'password-repeating-character'| translate:{repeatingCharCount:(passwordCharacterClass?.repeatingCharCount)} }}</strong>
            </ng-container>
        </li>
        <li *ngIf="passwordDisallowedCharacters?.disallowedChars">
            <ng-container *ngIf="!error?.invalidPasswordDisallowedChars">
            <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                    stroke='#156615' />
            </svg>
            can not contain {{passwordDisallowedCharacters?.disallowedChars}}
            </ng-container>
            <ng-container *ngIf="error?.invalidPasswordDisallowedChars">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                        fill='#B11206' />
                </svg>
                <strong>can not contain {{passwordDisallowedCharacters?.disallowedChars}}</strong>
            </ng-container>
        </li>
        <li *ngIf="passwordUsername">
            <ng-container *ngIf="!error?.invalidPasswordUsername">
            <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                    stroke='#156615' />
            </svg>
            {{'password-username'| translate:{usernameCharCount:passwordUsername?.numberOfCharacters} }}
            </ng-container>
            <ng-container *ngIf="error?.invalidPasswordUsername">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                        fill='#B11206' />
                </svg>
                <strong>{{'password-username'| translate:{usernameCharCount:passwordUsername?.numberOfCharacters} }}</strong>
            </ng-container>
        </li>
        <li *ngIf="passwordSequence">
            <ng-container *ngIf="!error?.invalidPasswordUsername">
            <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                    stroke='#156615' />
            </svg>
            {{ 'password-sequence' | translate }}
            </ng-container>
            <ng-container *ngIf="error?.invalidPasswordUsername">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                        fill='#B11206' />
                </svg>
                <strong>{{ 'password-sequence' | translate }}</strong>
            </ng-container>
        </li>
        <ng-container *ngIf="passwordCharacterClass">
            <li>
                <ng-container *ngIf="!error?.invalidPasswordLower">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                        stroke='#156615' />
                </svg>
                {{'password-lowercase'| translate }}
                </ng-container>
                <ng-container *ngIf="error?.invalidPasswordLower">
                    <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                            fill='#B11206' />
                    </svg>
                    <strong>{{'password-lowercase'| translate }}</strong>
                </ng-container>
            </li>
            <li>
                <ng-container *ngIf="!error?.invalidPasswordUpper">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                        stroke='#156615' />
                </svg>
                {{'password-uppercase'| translate }}
                </ng-container>
                <ng-container *ngIf="error?.invalidPasswordUpper">
                    <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                            fill='#B11206' />
                    </svg>
                    <strong>{{'password-uppercase'| translate }}</strong>
                </ng-container>
            </li>
            <li>
                <ng-container *ngIf="!error?.invalidPasswordNumber">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                        stroke='#156615' />
                </svg>
                {{'password-number'| translate }}
                </ng-container>
                <ng-container *ngIf="error?.invalidPasswordNumber">
                    <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                            fill='#B11206' />
                    </svg>
                    <strong>{{'password-number'| translate }}</strong>
                </ng-container>
            </li>
            <li>
                <ng-container *ngIf="!error?.invalidPasswordSpecial">
                <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M4.26666 7.99999L7.46666 10.6667L11.7333 5.33332M7.99999 15.4667C3.87627 15.4667 0.533325 12.1237 0.533325 7.99999C0.533325 3.87627 3.87627 0.533325 7.99999 0.533325C12.1237 0.533325 15.4667 3.87627 15.4667 7.99999C15.4667 12.1237 12.1237 15.4667 7.99999 15.4667Z'
                        stroke='#156615' />
                </svg>
                {{'password-special'| translate }}
                </ng-container>
                <ng-container *ngIf="error?.invalidPasswordSpecial">
                    <svg class='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M7.99999 14.9667C4.15241 14.9667 1.03333 11.8476 1.03333 7.99999H0.0333252C0.0333252 12.3999 3.60012 15.9667 7.99999 15.9667V14.9667ZM14.9667 7.99999C14.9667 11.8476 11.8476 14.9667 7.99999 14.9667V15.9667C12.3999 15.9667 15.9667 12.3999 15.9667 7.99999H14.9667ZM7.99999 1.03333C11.8476 1.03333 14.9667 4.15241 14.9667 7.99999H15.9667C15.9667 3.60012 12.3999 0.0333252 7.99999 0.0333252V1.03333ZM7.99999 0.0333252C3.60012 0.0333252 0.0333252 3.60012 0.0333252 7.99999H1.03333C1.03333 4.15241 4.15241 1.03333 7.99999 1.03333V0.0333252ZM4.44644 5.15358L7.64644 8.35358L8.35355 7.64647L5.15355 4.44647L4.44644 5.15358ZM7.64644 8.35358L10.8464 11.5536L11.5535 10.8465L8.35355 7.64647L7.64644 8.35358ZM5.15355 11.5536L8.35355 8.35358L7.64644 7.64647L4.44644 10.8465L5.15355 11.5536ZM8.35355 8.35358L11.5535 5.15358L10.8464 4.44647L7.64644 7.64647L8.35355 8.35358Z'
                            fill='#B11206' />
                    </svg>
                    <strong>{{'password-special'| translate }}</strong>
                </ng-container>
            </li>
        </ng-container>
        <ng-container *ngIf="passwordHistory?.numberOfPasswords>0">
            <li>
                {{'password-history'| translate:{previousPasswordCount:passwordHistory?.numberOfPasswords} }}
            </li>   
        </ng-container>
    </ul>
</div>