import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function passwordPolicyValidation(rules:any,userLoginDetails:any): ValidatorFn {  
  var passwordCharacterClass: any;
  var passwordLength: any;
  var passwordSequence: any;
  var passwordUsername: any;
  var passwordDisallowedCharacters: any;
  var passwordHistory: any;
  var passwordLifetime: any;
  var userLoginDetails: any;
  var passwordErrorMessage: any;
  var isAllowSymbols: any;
  var passwordValidation: any;
  for (let i of rules) {
   if (i.type == "character_class")
     passwordCharacterClass = i
   if (i.type == "length")
     passwordLength = i
   if (i.type == "sequence")
     passwordSequence = i
   if (i.type == "username")
     passwordUsername = i
   if (i.type == "disallowed_characters")
     passwordDisallowedCharacters = i
   if (i.type == "history")
     passwordHistory = i
   if (i.type == "lifetime")
     passwordLifetime = i
 }
   return (control: AbstractControl): ValidationErrors | null => {
       let password = control.value;
       var allowLowerCase = new RegExp(".*[a-z].*");
       var allowUpperCase = new RegExp(".*[A-Z].*");
       var allowNumbers = new RegExp(".*[0-9].*");
       var allowSymbols = new RegExp(".*[^A-Za-z0-9].*");
 
       var needed = passwordCharacterClass.requiredNumberOfCharClasses;
       var count = 0
       var pword = password.toLowerCase().trim();
       var pwordCaseSensitive = password.trim();
       var uname;
       if(userLoginDetails.getRawValue().passwordAccount)
       uname = userLoginDetails.getRawValue().passwordAccount.username.toLowerCase();
       if(userLoginDetails.getRawValue().username)
       uname = userLoginDetails.getRawValue().username.toLowerCase();
       var i, j, result = [];
       var sequence = "abcdefghijklmnopqrstuvwxyz1234567890"
       var temp:any={}
       var error:any={}
      
        // url check

        
       //to check for disallowed chars
       if (!passwordCharacterClass.allowLowerChars && allowLowerCase.test(password)) {
         error={'invalidPasswordDisallowedLowercase':{
           'invalidPassword': true,
           "message": "Password should not contain LowerCase"
         }}
         Object.assign(temp,error);
 
       }
       if (!passwordCharacterClass.allowUpperChars && allowUpperCase.test(password)) {
         error={'invalidPasswordDisallowedUppercase':{
           'invalidPassword': true,
           "message": "Password should not contain Upper Case"
         }}
         Object.assign(temp,error);
 
       }
       if (!passwordCharacterClass.allowNumChars && allowNumbers.test(password)) {
         error={'invalidPasswordDisallowedNumeric':{
           'invalidPassword': true,
           "message": "Password should not contain Numbers"
         }}
         Object.assign(temp,error);
 
       }
       if (!passwordCharacterClass.allowSpecialChars && allowSymbols.test(password)) {
         error={'invalidPasswordDisallowedSpecial':{
           'invalidPassword': true,
           "message": "Password should not contain special Characters"
         }}
         Object.assign(temp,error);
 
       }
 
       // //to check if username present inside password
       if (passwordUsername) {
        
         for (i = 0; i < uname.length; i++) {
           for (j = i + 1; j < uname.length + 1; j++) {
             if (uname.slice(i, j).length == passwordUsername.numberOfCharacters && pword.includes(uname.slice(i, j))) {
               passwordErrorMessage = "Password should not contain username";
               error={'invalidPasswordUsername':{
                 "invalidPassword": true,
                 "message": "Password should not contain username"
               }}
               Object.assign(temp,error);
             }
           }
         }
       }
 
 
 
       //to check if sequence present inside password
       if (passwordSequence) {
         for (i = 0; i < sequence.length; i++) {
           for (j = i + 1; j < sequence.length + 1; j++) {
             if (sequence.slice(i, j).length == passwordSequence.numberOfCharacters && pword.includes(sequence.slice(i, j))) {
               passwordErrorMessage = "Password should not contain sequence"
       error={'invalidPasswordSequence':{
         'invalidPassword': true,
         "message": "Password should not contain sequence"
       }}
       Object.assign(temp,error);
               
             }
           }
         }
       }
 
       //repeating characters
       if (passwordCharacterClass.repeatingCharCount) {
         var obj: any = {};
         for(let i = 0; i < pword.length; i++)
         {
              
             // Counting occurrences of s[i]
             var count_rep = 1;
             while (i + 1 < pword.length &&
              pword.charAt(i) === pword.charAt(i + 1))
             {
                 i++;
                 count_rep++;
             }
             if(count_rep>=passwordCharacterClass.repeatingCharCount){
                     passwordErrorMessage = "Password should not contain repeating characters"

             error={'invalidPasswordRepeating':{
               'invalidPassword': true,
               "message": "Password should not contain repeating characters"
             }}
             Object.assign(temp,error);
             }
         }
       }
      
         if (passwordDisallowedCharacters && pword.includes(passwordDisallowedCharacters.disallowedChars)) {
           passwordErrorMessage = "Password should not contain disallowed Characters"
          error={'invalidPasswordDisallowedChars':{
             'invalidPassword': true,
             'message': "Password should not contain disallowed Characters"
           }}
           Object.assign(temp,error);
         }
 
 
       if (passwordCharacterClass.allowLowerChars) {
         if (allowLowerCase.test(password)) {
           count++;
         } else if(needed >= count) {
           error={'invalidPasswordLower':{
             'invalidPassword': true,
             "message": "Password does not have lowercase characters"
           }}
           Object.assign(temp,error);
         }
       }
       if (passwordCharacterClass.allowUpperChars) {
         if (allowUpperCase.test(password)) {
           count++;
         } else if(needed >= count) {
           error={'invalidPasswordUpper':{
             'invalidPassword': true,
             "message": "Password does not have uppercase characters"
           }}
           Object.assign(temp,error);
         }
       }
       if (passwordCharacterClass.allowNumChars) {
         if (allowNumbers.test(password)) {
           count++;
         } else if(needed >= count) {
           error={'invalidPasswordNumber':{
             'invalidPassword': true,
             "message": "Password does not have numeric characters"
           }}
           Object.assign(temp,error);
         }
       }
       if (passwordCharacterClass.allowSpecialChars) {
         if (allowSymbols.test(password)) {
           count++;
         } else if(needed >= count) {
           error={'invalidPasswordSpecial':{
             'invalidPassword': true,
             "message": "Password does not have special characters"
           }}
           Object.assign(temp,error);
         }
       }
       if (needed < count + 1) {
         passwordValidation = true;
       }
       else {
         passwordValidation = false;
         passwordErrorMessage = "password does not contain required char class"
         error={'invalidPasswordCharClass':{
           'invalidPassword': true,
           'message': 'Password does not contain required char class'
         }}
         Object.assign(temp,error);
       }
       //length
       if (pword.length < passwordLength.min || pword.length > passwordLength.max) {
         error={'invalidPasswordLength': {
           'invalidPassword': true,
           "message": "Password length not matching policy"
         }}
         Object.assign(temp,error);
       }
       passwordErrorMessage = "please enter a valid password"
       return temp;      
     }
      
}
