import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/spinner/spinner.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/spinner/spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  constructor() { }
  @Input()
  size?:string;

  @Input()
  enabled?:boolean | string;

  @Input()
  class:string='';

  ngOnInit(): void {
  }

}
