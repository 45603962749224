import { Injectable } from '@angular/core';
import { AppConfigService } from '../service/appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class ManageProfileService {

  constructor(private appConfigService: AppConfigService) { }

  getIAMProxyCuiObj() {
    return this.appConfigService.cuiObj;
  }

  getPegasusProxyCuiObj() {
    return this.appConfigService.pegasusCuiObj;
  }

  fetchCurrentUser(cuid: any) {
    return this.getIAMProxyCuiObj().getPerson(
      { personId: cuid || this.appConfigService.authInfo.cuid }
    )
  }

  fetchCurrentUserPasswordAccount() {
    return this.getIAMProxyCuiObj().getPersonPassword({
      personId: this.appConfigService.authInfo.cuid
    })
  }

  fetchPasswordPolicyById(passwordPolicyId: any) {
    return this.getIAMProxyCuiObj().getPasswordPolicy(
      {
        policyId: passwordPolicyId
      }
    )
  }

  deleteGoogleAccount() {
    return this.getIAMProxyCuiObj().deleteGoogleAccount(
      {
        personId: this.appConfigService.authInfo.cuid
      }
    )
  }



  updateCurrentPerson(updatedPersonDetail: any, cuid: any) {
    return this.getPegasusProxyCuiObj().personUpdate({
      qs: ['cuid', cuid || this.appConfigService.authInfo.cuid],
      data: updatedPersonDetail
    })
  }

  updateCurrentPersonPassword(updatedPersonPassword: any) {
    return this.getIAMProxyCuiObj().updatePersonPassword({
      personId: this.appConfigService.authInfo.cuid,
      data: updatedPersonPassword
    })
  }


  fetchPasswordPolicies(cuId: string) {
    return this.getIAMProxyCuiObj().passwordPolicy(
      { cuid: cuId }
    )
  }

  resendActivationLink(cuid: any) {
    return this.getIAMProxyCuiObj().activateGenerateLink({
      data: { subject: cuid }
    })
  }

  verifyMFA(passCode: number, trackingId: string) {
    return this.getIAMProxyCuiObj().verifyMFA({
      id: this.appConfigService.authInfo.cuid,
      data: {
        passCode: passCode,
        trackingId: trackingId,
      }
    })
  }

  verifyMFAGA(passCode: number, trackingId: string) {
    return this.getIAMProxyCuiObj().verifyMFA({
      id: this.appConfigService.authInfo.cuid,
      data: {
        passCode: passCode,
        trackingId: trackingId,
        accountName : 'PEGASUS'
      }
    })
  }

  resendActivationLinkCSR(username: any, email: any) {
    return this.getIAMProxyCuiObj().resetGenerateLink({
      data: {
        subject: username,
        subjectType: "LOGIN_ID",
        idVerifyFields: {
          email: email
        }
      }
    })
  }

  submitPersonsReprocess(personRequestForReprocess:any){
    return this.getPegasusProxyCuiObj().submitPersonReprocess( { data: personRequestForReprocess })
  }

  unlockPerson(cuid: any) {
    return this.getIAMProxyCuiObj().unlockPersonPassword(
      {
        qs: [['subject', cuid]]
      }
    )
  }

  suspendPerson(cuid: any) {
    return this.getPegasusProxyCuiObj().suspendPerson(
      {
        qs: [['cuid', cuid]]
      }
    )
  }

  unsuspendPerson(cuid:any){
    return this.getPegasusProxyCuiObj().unsuspendPerson(
      { qs: [['cuid', cuid]] }
    )
  }

  deletePerson(cuid:any){
    return this.getPegasusProxyCuiObj().deletePerson(
      { qs: [['cuid', cuid]] }
    )
  }

  anonymizePerson(cuid: any) {
    return this.getPegasusProxyCuiObj().anonymizePerson({
      qs: [['cuid', cuid]],
    });
  }


  resetPersonPassword(cuid:any){
    return this.getIAMProxyCuiObj().resetPersonPassword(
      { qs: [['subject', cuid]] }
    )
  }

  getUnactivatedUsername(cuid: any) {
    return this.getIAMProxyCuiObj().getUsernameForCSR({
      qs: [['cuid', cuid]
      ]
    })
  }

  getOrganization(organizationId: any) {
    return this.getIAMProxyCuiObj().getOrganization({
      organizationId: organizationId
    })
  }

  sendSMSCode(cuId: string,mode: string,phoneType: string) {
    return this.getIAMProxyCuiObj().sendSMSCode(
      {
        id: cuId,
        qs: [['mode', mode],
        ['phoneType', phoneType]
        ]
      }
    )
  }


  sendEmailCode(cuId: string) {
    return this.getIAMProxyCuiObj().sendEmailCode(
      { id: cuId }
    )
  }

  getTOTP() {
    return this.getIAMProxyCuiObj().getTOTP(
      { id: this.appConfigService.authInfo.cuid }
    )
  }

  callPhone(cuId: string) {
    return this.getIAMProxyCuiObj().callPhone(
      { id: cuId }
    )
  }
}
