import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/service/appconfig.service';
import { SharedService } from '../shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/header/header.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/header/header.component.css']
})
export class HeaderComponent implements OnInit {
  isMyAccountCollapsed = true;
  isMenuCollapsed = true;

 
  constructor(private appConfigService: AppConfigService,
    public sharedService: SharedService,
    private router: Router) { }

  envProperties: any;
  isLoggedin: boolean = false;
  username!: String;
  originUri!:String;
  navbarElement: any;
  
  ngOnInit() {
    this.envProperties = this.appConfigService.envProperties;
    this.originUri=this.envProperties.solutionInstancesUrl;
    if (this.appConfigService.appContext?.user.name.given)
      this.isLoggedin = true;
    this.username = this.appConfigService.appContext?.user.name.given + ' ' + this.appConfigService.appContext?.user.name.surname;
    this.sharedService.updatedUsername.subscribe((emittedUsername: String) => {
      this.username=emittedUsername;
    })
  }

  logOut() {
    sessionStorage.clear();
    // setTimeout(() => {
        // window.location.reload();
        // setTimeout(() => {
            this.appConfigService.cuiObj.covLogout({
                redirect: this.envProperties.logoutUrl,
                qs: [['type', 'logout']],
            });
        // }, 100);
    // }, 100);
    setTimeout(() => {
      window.location.reload();
      // window.location.assign(this.envProperties.logoutUrl);
      // window.location.replace(this.envProperties.logoutUrl);
      // window.location.href = this.envProperties.logoutUrl;
  }, 100);
  }

  goToProfile(){
    this.sharedService.setProfilePage(true);
    this.router.navigate(['/manageProfile']);
    this.toggleMyAccount();
  }

  addStyles(){
    console.log("called");
    if(window.scrollY != 0){
      this.navbarElement = document.getElementById("navbar");
      this.navbarElement.classList.add("sitenav-shadow");
    }
      
  }

  toggleMenu(){
    this.isMenuCollapsed = !this.isMenuCollapsed;

    if(!this.isMyAccountCollapsed) {
      this.isMyAccountCollapsed = true;
    }
  }

  toggleMyAccount(){
    this.isMyAccountCollapsed = !this.isMyAccountCollapsed;

    if(!this.isMenuCollapsed) {
      this.isMenuCollapsed = true;
    }
  }
}
