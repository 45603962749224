import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { PasswordTooltipComponent } from 'src/app/shared/password-tooltip/password-tooltip.component';
import { HeaderComponent } from './header/header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PasswordTooltipComponent,
    ErrorMessageComponent,
    SpinnerComponent,
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbCollapseModule
  ],
  exports:[
    FooterComponent,
    HeaderComponent,
    PasswordTooltipComponent,
    ErrorMessageComponent,
    SpinnerComponent
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
