<div id="app" class="tab-pane" role="tabpanel" aria-labelledby="app-tab">
    <form class="row row-cols-1 row-cols-md-2 row-cols-lg-3" [formGroup]=" searchForm" (ngSubmit)="onSubmit()">
        <div class="col">
            <div class="search-group form-group">
                <label class="sr-only" for="app-search">Search applications</label>
                <input class="form-control border-muted" id="app-search" name="q" type="text"
                    placeholder="{{ 'search-applications' | translate }}" formControlName="searchKeyword">
                <button class="btn" (click)='onSubmit()' type='submit'>
                    <svg width="16" height="16" viewBox="0 0 25 24" fill="none" role="img" aria-hidden="true"
                        focusable="false">
                        <path stroke="currentColor" stroke-width="1.5"
                            d="M23.6237 23.2L17.2237 16.8M10.8237 20C5.52179 20 1.22372 15.702 1.22372 10.4C1.22372 5.09812 5.52179 0.800049 10.8237 0.800049C16.1257 0.800049 20.4237 5.09812 20.4237 10.4C20.4237 15.702 16.1257 20 10.8237 20Z">
                        </path>
                    </svg>
                    <span class="sr-only">Search</span>
                </button>
            </div>
        </div>
        <br>
    </form>

    <div class="center">
        <app-spinner [enabled]="appSpinner" [size]="'120px'" [class]="'text-primary'"></app-spinner>
    </div>
    <div *ngIf="!appSpinner">
        <div *ngIf="noResultsFound" colspan=6 translate>
            <p translate>searched-application <strong>{{searchedValue}}</strong> not-found</p>
        </div>
        <div class='card-layout row row-cols-1 row-cols-md-2 row-cols-lg-3'>
            <div class="col" *ngFor="let item of paginatedTabelData; let i = index">
                <div class="card card-body card-light">
                    <div class="d-flex justify-content-between">
                        <h3 #prouctTitle class="card-title h5" isEllipsisActive [ngClass]="{ 'line-clamp-2': item.discription && item.discription !== '', 'line-clamp-3 line-clamp-lg-4': !item.discription || item.discription === '' }">{{item.name}}</h3>

                        <app-spinner *ngIf="item.spinnerRequired" class='fav' [enabled]="item.spinnerRequired"
                            [size]="'28px'"></app-spinner>

                        <img *ngIf="!item.spinnerRequired && item.isFavorite" class="ml-1"
                            src="https://assets.ot.digital/img/star-solid.svg" width="25" height="24" role="button"
                            (click)='removeFavorite(item,i)' alt="#" />
                        <img *ngIf="!item.spinnerRequired && !item.isFavorite" class="ml-1"
                            src="https://assets.ot.digital/img/star-outline.svg" width="25" height="24" role="button"
                            (click)='addFavorite(item,i)' alt="#" />

                    </div>

                    <p class="card-text text-sm mb-2" isEllipsisActive [ngClass]="{ 'text-truncate': isTitleTruncated(prouctTitle), 'line-clamp-3': !isTitleTruncated(prouctTitle)}">{{item.discription}}</p>
                    <a class="more more-bold text-sm mt-auto" target="_blank" href="{{item.url}}" translate>open</a>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end mt-7" *ngIf="totalCount > 9">
            <form class="d-flex align-items-center mb-4 mb-md-0" action="#">
                <label class="text-nowrap mb-0 mr-1" for="limiter" translate>entries-per-page</label>
                <select class="bg-transparent border-0 font-weight-light text-primary" id="limiter" name="limiter" (change)="loadPaginatedTableData()" [(ngModel)]="cardsPerPage">
                    <option value=9 selected>9</option>
                    <option value=18>18</option>
                    <option value=27>27</option>
                </select>
                <button class="sr-only" type="submit">Submit</button>
            </form>
            <nav aria-label="paging">
                <ngb-pagination [collectionSize]="totalCount" [(page)]="currentPage" (pageChange)="loadPaginatedTableData()"
                    [pageSize]="cardsPerPage" [maxSize]="3">
                    <ng-template ngbPaginationPrevious>
                        <a class="page-link page-link-icon">
                            <svg width="32" height="32" viewBox="0 0 33 32" fill="none" role="img" aria-hidden="true"
                                focusable="false">
                                <path stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                    d="M19.2403 9.27673L12 16L19.2403 22.7232"></path>
                            </svg>
                            <span class="sr-only text-body">Previous</span>
                        </a>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <a class="page-link page-link-icon" href="#">
                            <svg width="32" height="32" viewBox="0 0 33 32" fill="none" role="img" aria-hidden="true" focusable="false">
                                <path stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                    d="M13.8333 22.9333L21.2999 16L13.8333 9.06665"></path>
                            </svg>
                            <span class="sr-only">Next</span>
                        </a>
                    </ng-template>
                </ngb-pagination>
            </nav>
        </div>
    </div>
</div>