import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { CustomAPI } from 'src/assets/APIs/customAPI';
import { IamApis } from 'src/assets/APIs/iamAPI';
import { CustomEnvAPI } from 'src/assets/APIs/customEnvAPI';
// import { AppConstants } from '../constants/AppConstants';
import { environment } from 'src/environments/environment';
import { AppContext } from '../model/app-context.entity';
import { EnvironmentConfigService } from './EnvironmentConfig.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { defs as CustomAPI } from './assets/json/idm-call-defs.json';

declare var cui: any;
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(
    private http: HttpClient,
    private location: Location,
    private enviornmentConfig: EnvironmentConfigService,
    private permissionsService: NgxPermissionsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  appConfig: any = null;
  cuiObj: any;
  pegasusCuiObj: any;
  authInfo: any;
  sysCuiObj: any;
  userContext: any = {};
  appContext: AppContext | undefined;
  envProperties: any;
  userOrg: any;
  customCallDefs:any;
  linkedPrimaryId: any;
  linkedSecondaryId: any;
  

  appInit(): Promise<any> {
    const envConfigData = this.loadEnv();
    const initCuiObj = envConfigData.then(() => this.initCUI());
    return Promise.all([
      envConfigData,
      initCuiObj,
    ]);
  }
  public loadEnv(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.enviornmentConfig.loadEnvironment(environment.APP_CONSTANTS).then((configData) => {
        this.envProperties = configData;
        this.loadEnvAPIs();
        resolve(true);
      });
    });
  }

  loadEnvAPIs() {
    this.customCallDefs=CustomEnvAPI.defs
       this.customCallDefs.forEach( (arrayItem:any) => {
          if(arrayItem.cmd=="resetGenerateLink")
          Object.assign(arrayItem.headers,{
            "contextCode": this.envProperties.contextCode 
          });
      });
  }

  initCUI(): Promise<any> {
    const promise1 = new Promise((resolve, reject) => {
      cui
        .api({
          sessionId: 1,
          retryUnseured: false,
          serviceUrl: this.envProperties.serviceUrl,
          proxyUrl: 'p/apiProxy',
          dataCallDefs: [
            IamApis.def,
            CustomAPI.defs,
            this.customCallDefs

          ],
          originUri: this.envProperties.originUri,
        })
        .then((obj: any) => {
          // if (this.checkIfUncesured() || true) {
            //   this.cuiObj = { ...obj };
            //   resolve(this.cuiObj);
          // } else {
          this.cuiObj = { ...obj };
          const jwtAuthHandler = () => {
          const currentUrl: any = window.location.href;
          const urlContainslinkedAccounts = currentUrl.includes('linkedAccounts');
          const urlContainsPrimaryCuid = currentUrl.includes('primary_cuid');
          const urlContainsSecondaryCuid = currentUrl.includes('secondary_cuid');
          if(urlContainslinkedAccounts && urlContainsPrimaryCuid && urlContainsSecondaryCuid) {
            sessionStorage.setItem('showLinkedAccountSuccessMsg', 'true');
            const redirectUrl = 'https://' + this.envProperties.originUri+'/#/linkedAccounts';
            return this.cuiObj.covAuth({
              originUri: this.envProperties.originUri,
              authRedirect: redirectUrl
            });
           
          } else {
            return this.cuiObj.covAuth({
              originUri: this.envProperties.originUri,
              authRedirect: window.location.href,
            });
          }
          };
          
          this.cuiObj
            .handleCovAuthResponse({
              selfRedirect: true,
            })
            .then((response: any) => {
              sessionStorage.setItem('isUserLoggedIn', 'true');
            })
            .fail((err: any) => { });

          this.authInfo = this.cuiObj.getCovAuthInfo();
                    this.cuiObj.setServiceUrl(this.envProperties.serviceUrl);
          this.cuiObj.setProxyUrl('')
          if(this.envProperties.env!="dell"){
            this.cuiObj.covAuthInfo({
              originUri: this.envProperties.originUri,
            });
            if (!this.authInfo.cuid && !this.checkIfUncesured()) {
              jwtAuthHandler();
            }
            this.cuiObj.setAuthHandler(jwtAuthHandler);
          }
  
          this.cuiObj.setProxyUrl('p/apiProxy')
          if (this.authInfo.cuid && !this.checkIfUncesured()) {
            Promise.all([
              this.cuiObj.getPersonRoles({
                personId: this.authInfo.cuid,

              }),
              this.cuiObj.getPersonPrivileges({
                personId: this.authInfo.cuid,
              }),
              this.cuiObj.getPerson({ personId: this.authInfo.cuid }),
            ]).then((res) => {
              let userInfo: any;
              const roleList = res[0].map((x: any) => x.name);
              const privilegeList = res[1].map((x: any) => x.privilegeName);
              userInfo = res[2];
              this.userContext.user = userInfo;
              this.userContext.roles = roleList;
              this.permissionsService.loadPermissions(this.userContext.roles);
              this.userContext.privileges = privilegeList;
              this.populateAppContext(this.userContext);
              this.getUserOrganization(userInfo.organization.id);
              resolve(res);
            })
              .catch(function (err) {
              });
          }  
          if (this.checkIfUncesured()) {
            resolve(true);
          }
          

          // obj
          // .doSysAuth({
          //   clientId: 'Gmg9uggjfWzKnoETERtPzyZJ6JhPR049',
          //   clientSecret: 'uCc2J3Ezp0umkbfw',
          // })
          // .then((token:any) => {
          //   // alert(token)
          //   // console.log(token)
          // })
           //  }
        });

    });

    //custom proxy cui object
    const promise2 = new Promise((resolve, reject) => {
      cui
        .api({
          sessionId: 1,
          retryUnseured: false,
          serviceUrl: this.envProperties.serviceUrl,///http:///
          proxyUrl: 'p/pegasusproxy',
          dataCallDefs: [
            IamApis.def,
            CustomAPI.defs,
            this.customCallDefs

          ],
          originUri: this.envProperties.originUri,
        })
        .then((obj: any) => {
          // if (this.checkIfUncesured()) {
          //   this.pegasusCuiObj = { ...obj };
          //   resolve(this.pegasusCuiObj);
          // } else {
          this.pegasusCuiObj = { ...obj };
          const jwtAuthHandler = () => {
            return this.pegasusCuiObj.covAuth({
              originUri: this.envProperties.originUri,
              authRedirect: window.location.href.split('#')[0] + '#/myApplications',
            });
          };
          this.authInfo = this.cuiObj.getCovAuthInfo();
          if(this.envProperties.env=="dell"){
          this.pegasusCuiObj.covAuthInfo({
            originUri: this.envProperties.originUri,
          });
          if (!this.authInfo.cuid && !this.checkIfUncesured()) {
            jwtAuthHandler();
          }
          this.cuiObj.setAuthHandler(jwtAuthHandler);
        }

        });
    });
  
    return promise1;
  }

  checkIfUncesured() {
    var url = window.location.href.split('#')[1];
    if (url) {
      if (url.includes('/resetPassword') ||
        url.includes('/securityQuestions') ||
        url.includes('/verifyEmail') ||
        url.includes('/walkup') ||
        url.includes('/PasswordReset') ||
        url.includes('/access-denied') ||
        url.includes('/email/verification')||
        url.includes('/verification')) {
        return true;
      }
    }
    return false;
  }


  populateAppContext(userContext: any) {
    this.appContext = new AppContext(this.authInfo.cuid, userContext.user, userContext.roles, userContext.privileges, this.appConfig, this.authInfo);
  }


  getUserOrganization(orgId: any) {
    return new Promise((resolve, reject) => {
      this.cuiObj
        .getOrganization({ organizationId: orgId })
        .then((org: any) => {
          sessionStorage.setItem('loggedInOrg', org.id);
          resolve(org);
        })
        .fail((error: any) => {
          reject(error);
        });
    });
  }
}
