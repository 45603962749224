import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/error-message/error-message.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/error-message/error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  error : string = '';
  public controlErrors: ValidationErrors | null | undefined | String;
  @Input()
  control!: AbstractControl  | null;

  @Input()
  control2!: String;

  constructor() { }

  ngOnInit(): void {
  
  }
  ngDoCheck() {
    if(this.control2)
      this.controlErrors=this.control2;
    if(this.control)
      this.controlErrors = this.control.errors?.message;
    
  }

}
