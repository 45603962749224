import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isEmail } from 'commons-validator-es';
import { AlertBoxService } from 'src/app/alert-box/alert-box.service';
import { AppConfigService } from 'src/app/service/appconfig.service';
import { ManageProfileService } from '../manage-profile.service';

@Component({
  selector: 'app-update-mfa',
  templateUrl: './update-mfa.component.html',
  styleUrls: ['./update-mfa.component.css']
})
export class UpdateMfaComponent implements OnInit {
  @Output() closeClicked: EventEmitter<any> = new EventEmitter();
  @Input() editForm !: FormGroup;
  updateMfaForm: FormGroup = new FormGroup({});
  submitSpinner: boolean = false;
  submitSpinnerMobile: boolean = false;
  submitSpinnerWarningModal: boolean = false;
  submitSpinnerModal: boolean = false;
  preferredModes = {
    'phone': "PHONE",
    'sms': "SMS",
    'email-mfa': "EMAIL",
    'google-authenticator': "GA"
  }
  ifEmailVisible = false;
  isGAVerified = false;
  ifPhoneVisible = false;
  ifGaVisible = false;
  removeButton = false;
  modeUpdateRequired = false;
  modeUpdateRequiredMain = false;
  modeUpdateRequiredMobile = false;
  passCodeRegexPattern = '^\\\D*(?:\\\d\\\D*){4,6}$'
  trackingId !: string;
  qrCodeImage !: string;
  public emailButton: string = 'send-passcode';
  public mainPhoneButton: string = 'send-passcode';
  public mobilePhoneButton: string = 'send-passcode';

  constructor(private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private fb: FormBuilder,
    private manageProfileService: ManageProfileService,
    private alertService: AlertBoxService) { }

  ngOnInit(): void {
    document.getElementById("update-mfa")?.click();
    this.loadReactiveForm(this.editForm);
    this.fetchQRCode();
  }

  loadReactiveForm(editForm: FormGroup) {
    this.updateMfaForm = this.fb.group({
      preferredMode: [editForm.controls['preferredMode'].value?.toUpperCase(), [Validators.required, this.ValidatePreferredMode.bind(this)]],
      main: [this.getPhoneNumber('main') || '', [Validators.required]],
      mobile: [this.getPhoneNumber('mobile') || '', [Validators.required]],
      email: [editForm.controls['email'].value, [Validators.required, Validators.email, this.emailValidator]],
      passCode: ['', [Validators.required, Validators.pattern(this.passCodeRegexPattern)]],
      phoneVerifiedMode: [editForm.controls['phoneVerifiedMode'].value?.toUpperCase()]
    });
    this.updateMfaForm.controls['passCode'].disable();
    this.hideProperties(editForm);
  }

  ValidatePreferredMode(control: AbstractControl): ValidationErrors | null {
    if (control != null && control.value && control.valueChanges) {
      this.hideProperties(this.editForm)
    }
    return null;
  }

  removeMode(mode: string) {
    if (mode === 'EMAIL') {
      this.updateMfaForm.controls['email'].enable();
      this.modeUpdateRequired = !this.modeUpdateRequired;
    }
    if (mode === 'MAIN') {
      this.updateMfaForm.controls['main'].enable();
      this.modeUpdateRequiredMain = !this.modeUpdateRequiredMain;
    } else if (mode === 'MOBILE') {
      this.updateMfaForm.controls['mobile'].enable();
      this.modeUpdateRequiredMobile = !this.modeUpdateRequiredMobile;
    }
  }

  hideProperties(editForm: FormGroup) {
    this.modeUpdateRequired = false;
    this.modeUpdateRequiredMain = false;
    this.modeUpdateRequiredMobile = false;
    this.emailButton = 'send-passcode';
    this.mainPhoneButton = 'send-passcode';
    this.mobilePhoneButton = 'send-passcode';
    if (this.updateMfaForm.controls.preferredMode != undefined) {
      this.updateMfaForm.controls['main'].enable();
      this.updateMfaForm.controls['mobile'].enable();
      this.updateMfaForm.controls['passCode'].disable();
      this.updateMfaForm.controls['passCode'].patchValue('');
      if (this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'EMAIL') {
        if (this.editForm.controls['preferredMode'].value?.toUpperCase() === 'EMAIL') {
          this.updateMfaForm.controls['email'].disable();
          this.modeUpdateRequired = true
        }
        this.ifEmailVisible = true;
        this.ifPhoneVisible = false;
        this.ifGaVisible = false;
      }
      if ((this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'SMS' ||
        this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'PHONE')) {
        if (this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'SMS' &&
          this.editForm.controls['preferredMode'].value?.toUpperCase() === 'SMS' &&
          this.editForm.controls['preferredMode'].value?.toUpperCase() !== 'EMAIL') {
          if (this.editForm.controls['phoneVerifiedMode'].value?.toUpperCase() === 'MAIN') {
            this.updateMfaForm.controls['main'].disable();
            this.modeUpdateRequiredMain = true;
          } else if (this.editForm.controls['phoneVerifiedMode'].value?.toUpperCase() === 'MOBILE') {
            this.updateMfaForm.controls['mobile'].disable();
            this.modeUpdateRequiredMobile = true;
          }
        }
        if (this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'PHONE' &&
          this.editForm.controls['preferredMode'].value?.toUpperCase() === 'PHONE' &&
          this.editForm.controls['preferredMode'].value?.toUpperCase() !== 'EMAIL') {
          if (this.editForm.controls['phoneVerifiedMode'].value?.toUpperCase() === 'MAIN') {
            this.updateMfaForm.controls['main'].disable();
            this.modeUpdateRequiredMain = true;
          } else if (this.editForm.controls['phoneVerifiedMode'].value?.toUpperCase() === 'MOBILE') {
            this.updateMfaForm.controls['mobile'].disable();
            this.modeUpdateRequiredMobile = true;
          }
        }
        this.ifPhoneVisible = true;
        this.ifGaVisible = false;
        this.ifEmailVisible = false;
      }
      if (this.updateMfaForm.controls['preferredMode'].value?.toUpperCase() === 'GA') {
        this.updateMfaForm.controls['passCode'].enable();
        // this.fetchQRCode();
        this.ifGaVisible = true;
        this.ifPhoneVisible = false;
        this.ifEmailVisible = false;
        if (this.editForm.controls['preferredMode'].value?.toUpperCase() === 'GA') {
          this.isGAVerified = true;
        }
      }
    }
  }

  emailValidator(control: AbstractControl): ValidationErrors | null {
    if (isEmail(control.value) && !(control.value.toLowerCase().includes("@opentext.com"))) {
      return null;
    }
    if (control.value === '' && (control.touched || control.dirty)) {
      return { invalidFormat: true, message: 'empty' };
    }
    return { invalidFormat: true, message: 'format' }
  }

  fetchQRCode() {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.getTOTP()
        .then((response: any) => {
          this.qrCodeImage = 'data:image/jpg;base64,' + response.qrCode;
          sessionStorage.setItem('qrCode', response);
          this.trackingId = response.trackingId;
          this.isGAVerified = false;
        })
        .fail((err: any) => {
          this.isGAVerified = true;
          reject(err);
        });
    })
    return promise
  }

  deleteGoogleAccount() {
    this.removeButton = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.deleteGoogleAccount()
        .then((response: any) => {
          this.removeButton = false;
          this.fetchQRCode();
        })
        .fail((err: any) => {
          reject(err);
        });
    })
    return promise
  }


  getPhoneNumber(phone: string) {
    var phoneArray: any[] = this.editForm.value.phones;
    if (phoneArray) {
      const index = phoneArray.findIndex(((userPhone: any) => userPhone.type === phone));
      if (index > -1) {
        return phoneArray[index].number;
      }
    }
  }

  patchPhoneNumber(phoneType: string) {
    var phoneArray: any[] = this.editForm.value.phones;
    var mode = this.updateMfaForm.controls['preferredMode'].value?.toUpperCase();
    if (phoneArray) {
      const index = phoneArray.findIndex(((userPhone: any) => userPhone.type === phoneType));
      if (index > -1) {
        const phonesArray = this.editForm.controls['phones'] as FormArray;
        const userPhone = phonesArray.controls[index] as FormGroup;
        userPhone.controls['number'].patchValue(this.updateMfaForm.controls[phoneType].value);
        this.editForm.updateValueAndValidity();
        this.sendSMSCodeWithUpdate(mode, phoneType);
      }
    }
  }

  sendSMSCodeWithUpdate(mode: string, phoneType: string) {
    this.submitSpinnerWarningModal = true;
    var data = Object.assign(this.editForm.getRawValue(), { "updateSourceSystem": 'SPA' });
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.updateCurrentPerson(data, '')
        .then((response: any) => {
          // this.validatePassCode('EMAIL');
          this.trackingId = data.trackingId;
          this.sendSMSCode(mode, phoneType);
        })
        .fail((err: any) => {
          this.submitSpinner = false;
          reject(err);
        });
    })
    return promise
  }

  sendNotification() {
    this.closeClicked.emit("close");
  }

  cancelled() {
    this.submitSpinner = false;
    this.submitSpinnerModal = false;
    this.submitSpinnerWarningModal = false;
    document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
  }

  validatePassCode(phoneType: string) {
    var mode = this.updateMfaForm.controls['preferredMode'].value?.toUpperCase()
    if (mode === 'EMAIL') {
      if (this.updateMfaForm.controls['email'].value !== this.editForm.controls['email'].value) {
        this.invokeWarningModal();
      } else {
        this.sendEmailCode();
      }
    }
    else if (mode === 'SMS' || mode === 'PHONE') {
      if (phoneType === 'MAIN' && this.updateMfaForm.controls['main'].value !== this.getPhoneNumber('main')) {
        this.invokeWarningModal();
      }
      else if (phoneType === 'MOBILE' && this.updateMfaForm.controls['mobile'].value !== this.getPhoneNumber('mobile')) {
        this.invokeWarningModal();
      }
      else {
        this.sendSMSCode(mode, phoneType)
      }
    }
  }

  private invokeWarningModal() {
    this.submitSpinner = false;
    document.getElementById("warning-modal-id")?.click();
    document.getElementById('update-mfa-modal')?.classList.add('is-blurred');
  }

  sendEmailCode() {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.sendEmailCode(this.editForm.controls['id'].value)
        .then((response: any) => {
          this.trackingId = response.trackingId;
          this.updateMfaForm.controls['passCode'].enable();
          document.getElementById("warning-cancel")?.click();
          document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
          this.submitSpinnerWarningModal = false;
          this.submitSpinner = false;
          resolve(response);
        })
        .fail((err: any) => {
          this.updateMfaForm.controls['passCode'].setErrors({ message: "invalidPasscode" });
          this.updateMfaForm
          reject(err);
        });
    })
    return promise
    // this.httpClient.get("assets/mock-json/sendCodeResponse.json").subscribe((data: any) => {
    //   console.log("MAIL SENT");
    //   this.trackingId = data.trackingId;
    //   this.updateMfaForm.controls['passCode'].enable();
    //   document.getElementById("warning-cancel")?.click();
    //   document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
    //   this.submitSpinnerWarningModal = false;
    //   this.submitSpinner = false;
    // })
  }

  sendSMSCode(mode: string, phoneType: string) {
    if (phoneType.toLocaleUpperCase() === 'MAIN') this.submitSpinner = true;
    else this.submitSpinnerMobile = true;
    this.submitSpinnerWarningModal = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.sendSMSCode(this.editForm.controls['id'].value, mode.toLocaleUpperCase(), phoneType.toLocaleUpperCase())
        .then((response: any) => {
          this.trackingId = response.trackingId;
          document.getElementById("warning-cancel")?.click();
          this.updateMfaForm.controls['passCode'].enable();
          document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
          this.submitSpinnerWarningModal = false;
          this.submitSpinnerMobile = false;
          this.submitSpinner = false;
          resolve(response);
        })
        .fail((err: any) => {
          this.submitSpinnerWarningModal = false;
          this.submitSpinnerMobile = false;
          this.submitSpinner = false;
          this.updateMfaForm.controls['passCode'].disable();
          if (phoneType.toLocaleUpperCase() === 'MAIN') {
            this.updateMfaForm.controls['main'].setErrors({
              message: 'Invalid Phone Number'
            })
          } else if (phoneType.toLocaleUpperCase() === 'MOBILE') {
            this.updateMfaForm.controls['mobile'].setErrors({
              message: 'Invalid Phone Number'
            })
          }
          document.getElementById("warning-cancel")?.click();
          document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
          reject(err);

        });
    })
    return promise
    // this.httpClient.get("assets/mock-json/sendCodeResponse.json").subscribe((data: any) => {
    //   console.log(mode+ " :: " + phoneType)
    //   this.trackingId = data.trackingId;
    //   document.getElementById("warning-cancel")?.click();
    //   this.updateMfaForm.controls['passCode'].enable();
    //   document.getElementById('update-mfa-modal')?.classList.remove('is-blurred');
    //   this.submitSpinnerWarningModal =false;
    //   this.submitSpinner =false;
    // })
  }



  sendEmailWithPersonUpdate() {
    this.editForm.controls['email'].patchValue(this.updateMfaForm.controls['email'].value);
    this.submitSpinnerWarningModal = true;
    var data = Object.assign(this.editForm.getRawValue(), { "updateSourceSystem": 'SPA' });
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.updateCurrentPerson(data, '')
        .then((response: any) => {
          this.trackingId = data.trackingId;
          this.sendEmailCode();
        })
        .fail((err: any) => {
          this.submitSpinner = false;
          reject(err);
        });
    })
    return promise
    // this.httpClient.get("assets/mock-json/personDetails.json").subscribe((data: any) => {
    //   // console.log(mode+ " :: " + phoneType)
    //   this.trackingId = data.trackingId;
    //   this.sendEmailCode();
    // })
  }

  sendSMSWithPersonUpdate() {
    if (this.updateMfaForm.controls['main'].value !== this.getPhoneNumber('main')) {
      this.patchPhoneNumber('main');
    } else if (this.updateMfaForm.controls['mobile'].value !== this.getPhoneNumber('mobile')) {
      this.patchPhoneNumber('mobile');
    }
  }


  sendPassCode() {
    var mode = this.updateMfaForm.controls['preferredMode'].value?.toUpperCase()
    if (mode === 'EMAIL') {
      this.sendEmailWithPersonUpdate();
    } else {
      this.sendSMSWithPersonUpdate();
    }
  }

  validateOTP() {
    this.submitSpinnerModal = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.verifyMFA(this.updateMfaForm.controls['passCode'].value, this.trackingId)
        .then((response: any) => {
          this.submitSpinnerModal = false;
          document.getElementById("update-mode-close")?.click();
          this.alertService.show('update-person-success', { classname: 'alert-success' });
        })
        .fail((err: any) => {
          this.updateMfaForm.controls['passCode'].setErrors({
            message: 'invalidPasscode'
          })
          this.submitSpinnerModal = false;
          reject(err);
        });
    })
    return promise
  }

  validateOTPGA() {
    this.submitSpinnerModal = true;
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService.verifyMFAGA(this.updateMfaForm.controls['passCode'].value, this.trackingId)
        .then((response: any) => {
          this.submitSpinnerModal = false;
          document.getElementById("update-mode-close")?.click();
          this.alertService.show('update-person-success', { classname: 'alert-success' });
        })
        .fail((err: any) => {
          this.updateMfaForm.controls['passCode'].setErrors({
            message: 'invalidPasscode'
          })
          this.submitSpinnerModal = false;
          reject(err);
        });
    })
    return promise
  }
}
