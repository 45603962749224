import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { UpdatePasswordComponent } from './manage-profile/update-password/update-password.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { MyApplicationComponent } from './my-application/my-application.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { AccountLinkingComponent } from './account-linking/account-linking.component';

const routes: Routes = [
  {
    path: 'walkup',
    loadChildren: () =>
      import('./walkup-registration/walkup-registration.module').then(
        (m) => m.WalkupRegistrationModule
      ),
  },
  { path: 'verifyEmail', component: EmailConfirmationComponent },
  { path: 'email/verification', component: EmailConfirmationComponent},
  { path: 'verification', component: EmailConfirmationComponent },
  {
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: 'manageProfile',
        component: ManageProfileComponent,
      },
      {
        path: 'myApplications',
        component: MyApplicationComponent,
      },
      {
        path: 'linkedAccounts',
        component: AccountLinkingComponent,
      },
      {
        path: 'search',
        component: SearchUserComponent,
        data: {
          permissions: {
            only: ['Help Desk Plus','Security Administrator'],
          },
        },
        canActivate: [NgxPermissionsGuard],
        children: [
          {
            path: 'manageAdminProfile',
            component: ManageProfileComponent,
          },
        ],
      },
      {
        path: '',
        redirectTo: 'myApplications' 
      }
    ],
  },
  {
    path: 'updatePassword',
    component: UpdatePasswordComponent,
  },
  { path: '**', component: ErrorPageComponent },
  { path: 'access-denied', component: ErrorPageComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}
