
import * as errorMessages from '../../assets/errorMessages.json';

export function getErrorMessage(apiError:any,defaultMessage:String): String { 
    var customError:String='';
    if(apiError){
    var apiErrorMessage=String(apiError.apiMessage);
    var apiErrorStatus=Number(apiError.status);
    var errorMessage: any = (errorMessages as any).default;
    errorMessage.forEach((k:any) =>{
        if(apiErrorMessage.includes(k.apiMessage))
        customError= k.customMessage;
    });

    if(apiErrorStatus==500)
        customError='500-error'
    if(apiErrorStatus==503)
        customError='503-error'
    if(apiErrorStatus==401)
        customError='401-error'
    if(apiErrorMessage=='IdToken inactivated' || apiErrorMessage=='Invalid Access token.' || apiErrorMessage=='Access token expired.'){
            sessionStorage.clear();
        window.location.reload();
        }
}
if(!customError){
    customError=defaultMessage;
}

    
    return customError;
}

