import { Injectable } from '@angular/core';
import { AppConfigService } from '../service/appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class AccountLinkingService {

    constructor(private appConfigService: AppConfigService) { }

    getLinkedAccountsList(primaryId: any){
        return this.getMyPegasusCuiObj().linkedAccountList({
            qs: [['primaryId', this.appConfigService.authInfo.cuid]]
        })
      }

      getLinkedAccountsListForSystemAdmin(primaryId: any){
        return this.getMyPegasusCuiObj().linkedAccountList({
            qs: [['primaryId', primaryId]]
        })
      }

    unlinkIdpLinkedSearchPage(idpId:any){
        return this.getMyPegasusCuiObj().unlinkIdpSearch(
          { qs: [['linkedId', idpId]] }
        )
      }

      unlinkIdpLinkedAccountsPage(idpId:any, loggedInUserId: any){
        return this.getMyPegasusCuiObj().unlinkIdpLinkedAccounts(
          { qs: [['linkedId', idpId], ['loggedInUserId', loggedInUserId]] }
        )
      }

      fetchCurrentUser() {
        return this.getMyPegasusCuiObj().getPerson(
          { personId: this.appConfigService.authInfo.cuid }
        )
      }

      getMyPegasusCuiObj() {
        return this.appConfigService.pegasusCuiObj;
      }

}