export class AppContext {
  cuid: string;
  user: any;
  roles: string[];
  privileges: string[];
  appConfig: any;
  authInfo: any;

  constructor(cuid: string, user: any, roles: string[], privileges: string[], appConfig: any, authInfo: any) {
    this.cuid = cuid;
    (this.user = user), (this.roles = roles);
    this.privileges = privileges;
    this.appConfig = appConfig;
    this.authInfo = authInfo;
  }
}
