<div class="container p-0">
    <div class="row row-gap row-cols-1">
        <ng-container *ngIf="!cuid || cuid=='null'">
            <div class="col col-lg-10">
                <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                    <header>
                        <h2 class="h6 heading-regular">
                            Search Users
                        </h2>
                    </header>
                    <div class="d-block d-md-flex">
                        <div class="form-group w-100 mr-0 mr-md-3 mr-lg-4 mb-md-0">

                            <select class="form-control border-muted" id="searchParam" formControlName="searchParam"
                                (change)="changeLabel()">
                                <option value="username" selected translate>user-id</option>
                                <option value="email" translate>email</option>
                                <option value="name" translate>search-name</option>
                            </select>
                        </div>
                        <div class="search-group form-group w-100 mb-0">
                            <label class="sr-only" for="searchKeyword">
                                Search query
                            </label>
                            <input class="form-control border-muted" type="search"
                                placeholder="{{ searchLabel | translate}}" id="searchKeyword"
                                formControlName="searchKeyword">
                            <button type="submit" style="margin-bottom: 20px;" class="btn"
                                translate>
                                <svg width="16" height="16" viewBox="0 0 25 24" fill="none" role="img" aria-hidden="true"
                                    focusable="false">
                                    <path stroke="currentColor" stroke-width="1.5"
                                        d="M23.6237 23.2L17.2237 16.8M10.8237 20C5.52179 20 1.22372 15.702 1.22372 10.4C1.22372 5.09812 5.52179 0.800049 10.8237 0.800049C16.1257 0.800049 20.4237 5.09812 20.4237 10.4C20.4237 15.702 16.1257 20 10.8237 20Z">
                                    </path>
                                </svg>
                                <span class="sr-only">Search</span></button>
                            <div *ngIf="this.searchForm.get('searchKeyword')?.invalid && this.searchForm.get('searchKeyword')?.touched"
                                class="help-block">
                                <app-error-message [control2]="'searchKeyword-required'"></app-error-message>
                            </div>
                        </div>
                    </div>

                    <!-- <hr> -->
                </form>
            </div>
            <div class="center" *ngIf="!searchParameters">

                <app-spinner [enabled]="spin" [size]="'120px'" [class]="'text-primary'"></app-spinner>
            </div>
            <div class="col pr-0">
                <div *ngIf="error  && !cuid" class="h6 heading-regular mb-3 pr-1 pr-md-2" translate><br>
                    search-error<br><br>
                </div>
                <div *ngIf="searchParameters && !cuid">
                    <div *ngIf="numberOfResults == 0" class="h6 heading-regular mb-3 pr-1 pr-md-2" translate>
                        Your search for <strong>{{this.searchParameters.searchKeyword}}</strong> did not
                        match any results
                    </div>
                    <div *ngIf="numberOfResults > 0">
                        <h2 class="h6 heading-regular mb-3 pr-1 pr-md-2" translate>
                            Results
                        </h2>
                        <div class="table-responsive table-responsive-shadow-md">
                            <table class="table table-borderless table-striped text-sm">
                                <thead>
                                    <tr>
                                        <th scope="col" *ngFor="let i of tableHeadings" id={{i}} translate>
                                            {{i}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="spin" class="center">
                                    <tr rowspan=100>
                                        <td colspan=40>

                                            <app-spinner [enabled]="spin" [size]="'120px'" [class]="'text-primary'">
                                            </app-spinner>
                                            <br> Fetching details
                                            <br><br><br>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!spin">
                                    <tr *ngFor="let row of tableData, let i = index">
                                        <td>
                                            <a [routerLink]="['/search/manageAdminProfile']"
                                                (click)="setCuid(row.cuid)" [state]="{data: row}">
                                                <div class="mb-0 text-truncate" style="max-width: 200px;" isEllipsisActive>{{row.firstName+'
                                                    '+row.lastName | titlecase}}
                                                </div>
                                            </a>
                                        </td>
                                        <td class="mb-0 text-truncate" style="max-width: 200px;" isEllipsisActive>
                                            {{row.userId | titlecase}}
                                        </td>
                                        <td>
                                            {{row.organisation | titlecase}}
                                        </td>
                                        <td class="mb-0 text-truncate" style="max-width: 200px;" isEllipsisActive>
                                            {{row.email | lowercase}}
                                        </td>
                                        <td>
                                            {{row.syncUpdateStatus || 'No' | titlecase}}
                                        </td>
                                        <td *ngIf="row.status === 'inactive'; else status">
                                            Deleted
                                        </td>
                                        <ng-template #status><td>
                                            {{row.status | titlecase}}</td>
                                      </ng-template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="cuid">
            <app-manage-profile (backclicked)="setCuid($event)" [userCuid]="userCuid"></app-manage-profile>
        </ng-container>
    </div>
    <ng-container *ngIf="!cuid">
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-end mt-7" *ngIf="numberOfResults > 10">
        <form class="d-flex align-items-center mb-4 mb-md-0" action="#">
            <label class="text-nowrap mb-0 mr-1" for="limiter" translate>entries-per-page</label>
            <select class="bg-transparent border-0 font-weight-light text-primary" id="limiter" name="limiter" (change)="loadTable(numberOfRows, numberOfPages)" [(ngModel)]="numberOfRows">
                <option value="10" selected>10</option>
                <option value="15">15</option>
                <option value="30">30</option>
            </select>
            <button class="sr-only" type="submit">Submit</button>
        </form>
        <nav aria-label="paging">
            <ngb-pagination [collectionSize]="numberOfResults" [(page)]="numberOfPages" (pageChange)="loadTable(numberOfRows, numberOfPages)"
                [pageSize]="numberOfRows" [maxSize]="3">
                <ng-template ngbPaginationPrevious>
                    <a class="page-link page-link-icon">
                        <svg width="32" height="32" viewBox="0 0 33 32" fill="none" role="img" aria-hidden="true"
                            focusable="false">
                            <path stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                d="M19.2403 9.27673L12 16L19.2403 22.7232"></path>
                        </svg>
                        <span class="sr-only text-body">Previous</span>
                    </a>
                </ng-template>
                <ng-template ngbPaginationNext>
                    <a class="page-link page-link-icon" href="#">
                        <svg width="32" height="32" viewBox="0 0 33 32" fill="none" role="img" aria-hidden="true" focusable="false">
                            <path stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                d="M13.8333 22.9333L21.2999 16L13.8333 9.06665"></path>
                        </svg>
                        <span class="sr-only">Next</span>
                    </a>
                </ng-template>
            </ngb-pagination>
        </nav>
    </div>
    </ng-container>
</div>