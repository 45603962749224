import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../service/appconfig.service';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { getErrorMessage } from '../shared/error-messages';
import { AlertBoxService } from '../alert-box/alert-box.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
  // templateUrl: '../../'+environment.path+'landing-page/landing-page.component.html',
  // styleUrls: ['../../'+environment.path+'landing-page/landing-page.component.css']
  
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router,
    private appConfigService: AppConfigService,
    private alertService: AlertBoxService,
    private sharedService: SharedService, private manageProfileService: ManageProfileService, ) {
      this.router.events.subscribe((val: any)=>{
      this.loadUrl();
      });

     }

  myApplications: boolean = false;
  search: boolean = false;
  manageProfile: boolean = false;
  accountLinking:boolean = false;
  title: string = '';
  envProperties: any;
  adminUri: string = '';
  sidebarActive = false;
  changeIcon :boolean =true;
  personDetails: any = {};
  public cuid: any;
  showLinkedAccountTab: boolean = false;
  public organisationName: any;
  error!: String;
  @Input() userCuid?: string;

  ngOnInit(): void {
    this.loadUrl();
    this.envProperties = this.appConfigService.envProperties;
    this.adminUri = this.envProperties.adminSpaUri;
    this.sharedService.getProfilePageSubscription().subscribe(value => {
      this.loadUrl();
    })
    if (this.router.url.includes('search/manageAdminProfile')) {
      if (this.userCuid) {
        this.cuid = this.userCuid;
      } 
      else {
        this.router.navigate(['/search']);
      }
    }
    this.loadUser(this.cuid);
  }

  toggleIcon(){
    this.changeIcon = !this.changeIcon;
  }

  loadUrl() {
    var url = this.router.url;
    if (url.includes('myApplications')) {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = false;
      this.accountLinking = false;
      this.title = 'my-applications';
    }
    if (url.includes('search')) {
      this.search = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.accountLinking = false;
      this.title = 'search-users';
    }
    if (url.includes('linkedAccounts')) {
      this.accountLinking = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.search = false;
      this.title = 'Linked Account';
    }
    if (url.includes('manageProfile')) {
      this.manageProfile = true;
      this.myApplications = false;
      this.search = false;
      this.accountLinking = false;
      this.title = 'my-profile';
    }
    if (url == '/') {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = true;
      this.loadSVG('myApplications');
      this.router.navigate(['/myApplications']);
      this.title = 'my-applications';
    }
  }


  loadSVG(myApplications: string) {
    // var url= this.router.url;
    if (myApplications === 'myApplications') {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = false;
      this.accountLinking = false;
    }
    if (myApplications === 'search') {
      this.search = true;
      this.myApplications = false;
      this.manageProfile = false;
      this.accountLinking = false;
    }
    if (myApplications === 'manageProfile') {
      this.manageProfile = true;
      this.myApplications = false;
      this.search = false;
      this.accountLinking = false;
    }
    if (myApplications === 'accountLinking') {
      this.accountLinking = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.search = false;
    }
  }

  loadTitle(title: string) {
    this.title = String(title);
  }

  toggleSidebar(){
    this.sidebarActive = !this.sidebarActive;
  }

  loadUser(cuid: any) {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .fetchCurrentUser(cuid)
        .then((response: any) => {
          this.personDetails = response;
          this.loadOrganization();
          // if(this.personDetails.userType == 'Employee' || this.personDetails.userType == 'employee') {
          //   this.showLinkedAccountTab = false;
          // } else {
          //   this.showLinkedAccountTab = true;
          // }
          resolve(response);
        })
        .fail(function (err: any) {
          reject(err);
        });
    });
    return promise;
  }

  loadOrganization() {
    const promise = new Promise((resolve, reject) => {
      this.manageProfileService
        .getOrganization(this.personDetails.organization.id)
        .then((response: any) => {
          this.organisationName = response.name;
          if(this.organisationName == 'Employee') {
            this.showLinkedAccountTab = false;
          } else {
            this.showLinkedAccountTab = true;
          }
          // this.personDetails.orgName = response.name;
          resolve(response);
        })
        .fail((err: any) => {
          this.error = getErrorMessage(
            err?.responseJSON,
            'load-organisation-error'
          );
          this.alertService.show(String(this.error), {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

}
