<button class="float-end" id="reset-password" href="" type="button" hidden data-target="#reset-password-modal"
    data-toggle="modal" data-backdrop="static">Open</button>

<div id="reset-password-modal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="reset-password-modal-label" aria-modal="true" role="dialog">
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 offset-lg-2">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div class="modal-content bg-transparent border-0">
                        <div class="card card-body card-body-lg card-primary-top shadow pb-0 px-md-0 pt-6 pt-md-8">
                            <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close reset password modal" (click)="sendNotification()">
                                <span aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M1.3999 1.3999L20.5999 20.5999M1.3999 20.5999L20.5999 1.3999" stroke="#0066FF" stroke-width="1.5" />
                                    </svg>
                                </span>
                            </button>

                            <div id="resend-password-form" [ngClass]="!showResetPasswordContent? 'show':'collapse'" class="modal-body p-0">
                                <div class="container">
                                    <div class="row">
                                        <div class="col col-md-10 offset-md-1">
                                            <h2 id="reset-password-modal-label" class="mb-2 mb-md-3 mb-lg-6">Reset user's password</h2>
                                            <div class="row">
                                                <div class="col-12">
                                                    <p class="lead font-weight-bold mb-1">User details</p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-6">
                                                            <p class="text-sm mb-0">Last name:</p>
                                                            <p class="font-weight-bold">{{personDetails.name.surname}}</p>
                                                            </div>
                                                        <div class="col-12 col-md-6">
                                                            <p class="text-sm mb-0">Email:</p>
                                                            <p class="font-weight-bold">{{personDetails.email}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col col-md-10 offset-md-1 pb-6 pb-md-9">
                                            <div>
                                                <button id="reset-password-submit" class="btn btn-primary mt-5" type="submit" (click)="resetPassword()">Reset Password
                                                    <app-spinner [enabled]="submitSpinnerResetPassword" [size]="'23px'" [class]="'text-light ml-2'"></app-spinner>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="reset-password-content" [ngClass]="showResetPasswordContent? 'show':'collapse'" class="modal-body p-0">
                                <div class="container">
                                    <div class="row">
                                        <div class="col col-md-10 offset-md-1">
                                            <h2 class="mb-2 mb-md-3 mb-lg-6">Reset user's password</h2>

                                            <div class="row">
                                                <div class="col-12 mb-4">
                                                    <p class="lead font-weight-bold mb-1">1. The password for {{personDetails.name.given + ' ' + personDetails.name.surname}} has been successfully reset</p>
                                                    <p class="text-sm mb-1">The first half of the randomly generated password is:</p>
                                                    <div class="pill pill-primary">{{firstHalfPassword}}</div>
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <p class="lead font-weight-bold mb-0">2. Give the user the first half of their new password before ending the call</p>
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <p class="lead font-weight-bold mb-1">3. The second half of the password has been emailed to the registered email address {{personDetails.email}}</p>
                                                    <p class="text-sm mb-1">Note:</p>
                                                    <ol class="mt-0 pl-2">
                                                        <li>All characters are needed to logon</li>
                                                        <li>The randomly generated password will be uppercase characters only</li>
                                                        <li>Circular characters will always be the numeral zero</li>
                                                        <li>After successfully logging in with the randomly-generated password, the user can select any combination of characters in upper and lower case to create their new password</li>
                                                    </ol>
                                                </div>
                                            </div>

                                            <div class="row pb-6 pb-md-9">
                                                <div class="col">
                                                    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close Edit basic information Modal" (click)="sendNotification()">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>