import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css'],
  // templateUrl: '../../../'+environment.path+'shared/error-page/error-page.component.html',
  // styleUrls: ['../../../'+environment.path+'shared/error-page/error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
    /* this.translate.get('not-authorized').subscribe((text:string) => this.sharedService.setHeaderTitle(text));
    this.sharedService.setHeaderSubTitle(''); */
  }
}
