import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchTable } from '../model/searchTable.model';
import { getErrorMessage } from '../shared/error-messages';
import { SharedService } from '../shared/shared.service';
import { SearchUserService } from './search-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.css'],
  // templateUrl: '../../'+environment.path+'search-user/search-user.component.html',
  // styleUrls: ['../../'+environment.path+'search-user/search-user.component.css']
})
export class SearchUserComponent implements OnInit {
  searchForm: FormGroup = new FormGroup({});
  numberOfResults!: number;
  spin: boolean = false;
  tableHeadings = [
    'name',
    'user-id',
    'organisation',
    'email',
    'sync-status',
    'status',
  ];
  searchParameters: any;
  cuid!: string|null;
  tableData: SearchTable[] = [];
  numberOfRows: number = 10;
  numberOfPages: number = 1;
  paginationDisable: boolean = false;
  searchLabel: string = 'enter-username';
  error!: String;
  searchKeywordPatternRegex: string = '.*\\S.*';
  userCuid!: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchUserService: SearchUserService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.sharedService.syncStatusUpdated.subscribe(
      (emittedTable: SearchTable) => {
        const index = this.tableData.findIndex(
          (table) => table.cuid === emittedTable.cuid
        );
        if (
          emittedTable.syncUpdateStatus &&
          emittedTable.syncUpdateStatus !== ''
        )
          this.tableData[index].syncUpdateStatus =
            emittedTable.syncUpdateStatus;
        if (emittedTable.status && emittedTable.status !== '')
          this.tableData[index].status = emittedTable.status;
        if (emittedTable.firstName && emittedTable.firstName !== '')
          this.tableData[index].firstName = emittedTable.firstName;
        if (emittedTable.lastName && emittedTable.lastName !== '')
          this.tableData[index].lastName = emittedTable.lastName;
      }
    );
  }

  ngOnInit(): void {
    /* Commenting the Title and Sub Title as it is decided to remove the sub title for this release */
    /* this.translate.get('search-header-title').subscribe((text: string) => this.sharedService.setHeaderTitle(text));
    this.translate.get('search-header-subtitle').subscribe((text: string) => this.sharedService.setHeaderSubTitle(text)); */
    this.loadForm();
    this.router.navigate(['search']);
    // this.route.queryParams.subscribe(params => {
    //   this.cuid = params['userCuid'];
    // })
  }
  setCuid(cuid: string|null) {
    if(cuid)
    this.userCuid = cuid;

    this.cuid = cuid;
  }

  loadForm() {
    this.searchForm = this.fb.group({
      searchParam: ['username'],
      radioButton: [''],
      searchKeyword: [
        '',
        [
          Validators.required,
          Validators.pattern(this.searchKeywordPatternRegex),
        ],
      ],
    });
  }

  onSubmit() {
    if (this.searchForm.valid) {
      this.spin = true;
      this.searchParameters = null;
      this.numberOfResults = -1;
      if (this.searchForm.value.searchParam == 'username') {
        this.searchParameters = this.searchForm.value;
        this.loadTable(this.numberOfRows, 1);
        this.error = '';
      } else {
        const promise = new Promise((resolve, reject) => {
          this.searchUserService
            .countPersons(
              this.searchForm.controls['searchKeyword'].value.trim(),
              this.searchForm.controls['searchParam'].value
            )
            .then((response: any) => {
              this.searchParameters = this.searchForm.value;
              this.numberOfResults = response;
              this.loadTable(this.numberOfRows, 1);
              this.error = '';
              resolve(response);
            })
            .fail((err: any) => {
              this.spin = false;
              this.error = getErrorMessage(err?.responseJSON, 'search-error');
            });
        });
      }
    } else {
      this.searchForm.markAllAsTouched();
    }

    // this.searchParameters = this.searchForm.value;
    //       this.numberOfResults = 50;
    //       this.loadTable();
  }

  loadTable(numberOfRows: number, numberOfPages: number) {
    this.spin = true;
    return new Promise((resolve, reject) => {
      if (this.searchParameters) {
        // if(this.searchParameters.searchParam=="username"){
        //   this.numberOfResults=1;
        // }
        this.searchUserService
          .searchPersons(
            this.searchParameters.searchKeyword.trim(),
            this.searchParameters.searchParam,
            numberOfRows,
            numberOfPages
          )
          .then((response: any) => {
            this.tableData = response;
            if (this.searchForm.value.searchParam == 'username') {
              this.numberOfResults = this.tableData.length;
            }
            this.spin = false;
            this.error = '';
            resolve(response);
          })
          .fail((err: any) => {
            this.spin = false;
            this.error = getErrorMessage(err?.responseJSON, 'search-error');
          });
      }
    });
    // this.httpClient.get("assets/mock-json/searchPerson.json").subscribe((data : SearchTable[] | any)=> {
    //   this.tableData = data;
    //   console.log('Inside after load');
    //   this.spin = false;
    //   this.error = '';
    // })
  }

  changeLabel() {
    var param = this.searchForm.controls['searchParam'].value;
    if (param == 'username') this.searchLabel = 'enter-username';
    if (param == 'name') this.searchLabel = 'enter-fullname';
    if (param == 'email') this.searchLabel = 'enter-mail';
  }
}
