import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertBoxService } from './alert-box.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css'],
  // templateUrl: '../../'+environment.path+'alert-box/alert-box.component.html',
  // styleUrls: ['../../'+environment.path+'alert-box/alert-box.component.css']
})
export class AlertBoxComponent implements OnInit {

  constructor(public alertservice:AlertBoxService) { }

  ngOnInit(): void {
  }

  isTemplate(alert :any) { return alert.message instanceof TemplateRef; }

}
