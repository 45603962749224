import { Component, OnInit } from '@angular/core';
import { IdpDetails } from '../model/idp.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfigService } from '../service/appconfig.service';
import { AlertBoxService } from '../alert-box/alert-box.service';
import { TranslateService } from '@ngx-translate/core';
import { PackageService } from '../model/packageServices.model';
import { HttpClient } from '@angular/common/http';
import { AccountLinkingService } from './account-linking.service';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-account-linking',
  templateUrl: './account-linking.component.html',
  styleUrls: ['./account-linking.component.css']
})
export class AccountLinkingComponent implements OnInit {
  public IdpDetails!: IdpDetails;
  dummyIdpModel: any[] = [];
  copydummyIdpModel: any;
  searchForm: FormGroup = new FormGroup({});
  totalCount: number = 0;
  cardsPerPage: number = 9;
  currentPage: number = 1;
  paginatedTabelData: PackageService[] = [];
  tabelData: PackageService[] = [];
  linkedAccounts: boolean = false;
  alreadylinkedAccounts: boolean = true;
  searchText: string = '';
  primaryId = '38324483F508426DBB55C64CEE2B68E5';
  selectedIdp: any;
  currentUserDetails: any;
  showLinkedListData: boolean = false;
  public userRoles: string[] | undefined;
  unlinkFlag: boolean = false;
  envProperties: any;
  authLinkedAccountUrl: any;
  sessionShowSuccessMsg: any;
  selectedLanguage: any;
  
  constructor(private appConfigService: AppConfigService, private _location: Location, private location: LocationStrategy, private manageProfileService: ManageProfileService, private alertService: AlertBoxService, private accountLinkingService: AccountLinkingService, private fb: FormBuilder, private alertBoxService: AlertBoxService, private translate: TranslateService, private http: HttpClient, private router: Router) {
    this.searchForm = this.fb.group({
      searchKeyword: ['', Validators.required]
    });
    history.pushState(null, '', window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      this.router.navigateByUrl(this.router.url);
      this.alertService.show("back-button-disabled", { classname: 'alert-danger' });
    });
  }

  ngOnInit(): void {
    const currentUrl = this._location.path();
    const urlContainsPrimaryCuid = currentUrl.includes('primary_cuid');
    const urlContainsSecondaryCuid = currentUrl.includes('secondary_cuid')
    this.sessionShowSuccessMsg = JSON.parse(sessionStorage.getItem('showLinkedAccountSuccessMsg') || 'false');
    if(this.sessionShowSuccessMsg){
    }
    if(urlContainsPrimaryCuid && urlContainsSecondaryCuid || this.sessionShowSuccessMsg){
      this.alertService.show( 'Your account is now linked. You can unlink non-primary accounts anytime in the ‘Linked accounts’ tab.', {
        classname: 'alert-success',
      });
      sessionStorage.removeItem('showLinkedAccountSuccessMsg');
    }
    this.envProperties = this.appConfigService.envProperties;
    this.authLinkedAccountUrl = this.envProperties.authLinkedAccountUrl;
    this.userRoles = this.appConfigService.appContext?.roles;
    this.selectedLanguage = this.appConfigService?.appContext?.user?.language;
    this.copydummyIdpModel = this.dummyIdpModel;
    this.totalCount = this.dummyIdpModel.length;
    this.IdpDetails = new IdpDetails();
    this.loadUser();
    this.getLinkiedAccountList();
    // setTimeout(()=>{                     
      this.removeHeaderInformation();
  }

  removeHeaderInformation(){
    this.router.navigate([this.location.path().split('?')[0]], {
      queryParams: {
        'cuid': null,
        'xsrfToken': null,
        'primary_cuid': null,
        'secondary_cuid': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  loadUser() {
    const promise = new Promise((resolve, reject) => {
      let cuid = this.appConfigService.authInfo.cuid;
      this.manageProfileService
        .fetchCurrentUser(cuid)
        .then((response: any) => {
          this.currentUserDetails = response;
          resolve(response);
        })
        .fail(function (err: any) {
          reject(err);
        });
    });
    return promise;
  }

  getLinkiedAccountList() {
    return new Promise((resolve, reject) => {
        this.accountLinkingService.getLinkedAccountsList(this.primaryId)
          .then((response: any) => {
            this.dummyIdpModel = response;
            if(this.dummyIdpModel.length > 0 ) {
              this.showLinkedListData = true;
            } else {
              this.showLinkedListData = false;
            }
            if ( this.userRoles?.indexOf('Security Administrator') !== -1 ) {
              this.unlinkFlag = true;
            } else {
              this.unlinkFlag = false;
            }
            resolve(response);
          })
          .fail((err: any) => {
            // this.error = getErrorMessage(err?.responseJSON, 'search-error');
          });

    });
  }

  confirmUnlinkIdp(idp: any) {
    this.selectedIdp = idp;
  }

  unlinkIdp(id: any, userName: any) {  
    let loggedInUserId =   this.appConfigService.authInfo.cuid;
        const promise = new Promise((resolve, reject) => {
      this.accountLinkingService
        .unlinkIdpLinkedAccountsPage(id, loggedInUserId)
        .then((response: any) => {
          if(response.statusCode == 200) {
            this.alertService.show("Your account has been unlinked. You  can re-link  accounts  anytime  in the ‘Linked accounts’ tab.", {
              classname: 'alert-success',
            });
            this.getLinkiedAccountList();
            resolve(response);
          }
        })
        .fail((err: any) => {
          this.alertService.show('Account unlink failed. Please try again.', {
            classname: 'alert-danger',
          });
        });
    });
    return promise;
  }

  onSubmit() {

  }

  loadPaginatedTableData() {
    this.paginatedTabelData = this.tabelData.slice((this.currentPage - 1) * this.cardsPerPage, this.currentPage * this.cardsPerPage);
  }

  goToLinkedAcc() {
    // this.alreadylinkedAccounts = false;
    // this.linkedAccounts = true;
    window.location.href = 'https://auth.dev.otiam.opentext.com/account/link';
  }
  
  goToAlreeadyLinkedAcc() {
    this.alreadylinkedAccounts = true;
    this.linkedAccounts = false;
  }
  
  get filteredItems() {
    return this.dummyIdpModel.filter((item) =>
      item.providerType.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
